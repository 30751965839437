import { paymentsFederal } from '../constants/generalConstants';
const taxPaymentsRowForComp = (numbersData: any) => {
  const array = [
    {
      index: 0,
      descriptionElement: paymentsFederal[0]['element'],
      federalValue: numbersData.fedWagesWithholding,
      stateValue: numbersData.stateWagesWithholding
    },
    {
      index: 1,
      descriptionElement: paymentsFederal[1]['element'],
      federalValue: numbersData.fedPYOP,
      stateValue: numbersData.statePYOP
    },
    {
      index: 2,
      descriptionElement: paymentsFederal[2]['element'],
      federalValue: numbersData.fedQ1,
      stateValue: numbersData.stateQ1
    },
    {
      index: 3,
      descriptionElement: paymentsFederal[3]['element'],
      federalValue: numbersData.fedQ2,
      stateValue: numbersData.stateQ2
    },
    {
      index: 4,
      descriptionElement: paymentsFederal[4]['element'],
      federalValue: numbersData.fedQ3,
      stateValue: numbersData.stateQ3
    },
    {
      index: 5,
      descriptionElement: paymentsFederal[5]['element'],
      federalValue: numbersData.fedQ4,
      stateValue: numbersData.stateQ4
    },
    {
      index: 6,
      descriptionElement: paymentsFederal[6]['element'],
      federalValue: numbersData.fedCredits,
      stateValue: numbersData.stateCredits
    }
  ];

  return array;
};

export default taxPaymentsRowForComp;
