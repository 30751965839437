import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@emotion/react';
import { Button } from '@mui/material';
import { Tooltip } from '@mui/material';
import { buttonTheme } from './themesLoginDropDown';
import LoginIcon from '@mui/icons-material/Login';
import './loginDropDown.scss';

interface LoginButtonProps {
    open: boolean;
}

const LoginButton = ({ open }: LoginButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <ThemeProvider theme={buttonTheme}>
      <Tooltip title="Login">
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={() => loginWithRedirect()}
        >
          <LoginIcon />
        </Button>
      </Tooltip>
    </ThemeProvider>
  );
};

export default LoginButton;
