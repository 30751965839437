import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import '../index.scss';
import { themeWorksSans } from './themeWorksSans';

interface ElevationState {
    [key: string]: number;
}

const UserCards: React.FC = () => {
  const theme = useTheme();
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  // State to manage elevation for each card with TypeScript interface
  const [elevation, setElevation] = useState<ElevationState>({ card1: 3, card2: 3, card3: 3 });

  const handleMouseOver = (card: string) => {
    setElevation(prev => ({ ...prev, [card]: 10 }));
  };

  const handleMouseOut = (card: string) => {
    setElevation(prev => ({ ...prev, [card]: 3 }));
  };

  const cardData = [
    {
      id: 0,
      title: 'Everyday Individual',
      description: 'Homeowners, W2 employees, retirees, side businesses, families, rental real estate owners'
    },
    {
      id: 1,
      title: 'Self-Employed',
      description: 'Business owners, freelancers, contractors, entrepreneurs'
    },
    {
      id: 2,
      title: 'Self-Directed Investors',
      description: 'Investors in public stocks, bonds, stock options, crypto, or other assets'
    }
  ]

  return (
    <Box
      sx={{
        paddingBottom: { 
          xs: '22%',
          sm: '17%',
          md: '12%',
          lg: '7%',
        },
        paddingTop: { 
          xs: '14%', 
          sm: '11%', 
          md: '8%',
          lg: '5%',
        },
      }}
    >
      <ThemeProvider theme={themeWorksSans}>
        <Container 
          maxWidth={isXLargeScreen ? 'xl' : 'lg'}
        >
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom
            sx={{
              textAlign: 'center',
              pb: 2,
              fontWeight: '600',
              fontSize: {
                xs: '32px',  // smaller font size on extra small screens
                sm: '35px',  // slightly larger font size on small screens
                md: '38px',
              }
            }}
          >
            Types of Taxpayers
          </Typography>
          <Grid container spacing={3} alignItems="stretch">
            {cardData.map((element, index) => (
              <Grid item xs={12} sm={6} md={4} key={element.id}>
                <Card
                  sx={{
                    my: 2,
                    px: 2,
                    py: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flexGrow: 2,
                    minHeight: '70%',
                    transition: 'transform 0.3s',
                    transform: elevation[`card${index + 1}`] === 10 ? 'scale(1.05)' : 'none' // Scales up the card on hover
                  }}
                  onMouseOver={() => handleMouseOver(`card${index + 1}`)}
                  onMouseOut={() => handleMouseOut(`card${index + 1}`)}
                  elevation={elevation[`card${index + 1}`]}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography 
                      variant="h5" 
                      component="h3"
                      sx={{ textAlign: 'center', p: 1, mb: 2, fontWeight: '500' }}
                    >
                      {element.title}
                    </Typography>
                    <Typography variant="body1">
                      {index === 0 && element.description}
                      {index === 1 && element.description}
                      {index === 2 && element.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    </Box>
  );
}

export default UserCards;
