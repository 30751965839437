import './loginDropDown.scss';

interface ConfirmationBoxProps {
    message: string;
    isOpen: boolean;
    sendToApi: boolean;
    handleConfirmation: () => void;
    handleCancel: () => void;
}

const ConfirmationBox = ({
  message,
  isOpen,
  sendToApi,
  handleConfirmation,
  handleCancel
}: ConfirmationBoxProps
) => {

  if (!isOpen) {
    return null;
  }
  else {
    return (
      <div className="confirmation-box-overlay">
        <div className="confirmation-box">
          <p>{message}</p>
          {sendToApi ?
            <div className="login-drop-down-button-group">
              <div className="login-drop-down-button-group-confirmation">
                <button onClick={handleConfirmation}>OK</button>
              </div>
              <div className="login-drop-down-button-group-cancel">
                <button onClick={handleCancel}>CANCEL</button>
              </div>
            </div>
            :
            <div className="login-drop-down-button-group">
              <div className="login-drop-down-button-group-confirmation">
                <button onClick={handleConfirmation}>OK</button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
};

export default ConfirmationBox;
