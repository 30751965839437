import { schDLineSix, schDLineThirteen } from '../constants/generalConstants';
import { ISchDModel } from '../types';

const schDTaxWorksheetLineThresholdCalculation = (filingStatus: string, schDLineNumber: string): number => {
  let array: ISchDModel[];

  if (schDLineNumber === 'SchDLineSix') {
    array = schDLineSix;
  }
  else {
    array = schDLineThirteen;
  }

  for (let i = 0; i < array.length; i++) {
    if (filingStatus === array[i]['filingStatus']) {
      return array[i]['value'];
    }
  }
  console.log('error in schDTaxWorksheetLineThresholdCalculation');
  return 0;
};

export default schDTaxWorksheetLineThresholdCalculation;
