const quarterFactorCalcFunction = (quarter: string): number => {

  if (quarter === '1') {
    return .25;
  }
  else if (quarter === '2') {
    return .5;
  }
  else if (quarter === '3') {
    return .75;
  }
  else {
    return 1;
  }
};

export default quarterFactorCalcFunction;
