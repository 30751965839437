import { useState } from 'react';
import summationFunction from '../../functions/summationFunction';
import annualizationFactorCalcFunction from '../../functions/annualizationFactorCalcFunction';
import annualizeDataFunction from '../../functions/annualizeDataFunction';
import childTaxCreditFunction from '../../functions/childTaxCreditFunction';
import seTaxFunction from '../../functions/seTaxFunction';
import addCommasToSingleNumbers from '../../functions/addCommasToSingleNumbers';
import addCommasToSetOfNumbers from '../../functions/addCommasToSetOfNumbers';
import standardFunction from '../../functions/standardFunction';
import standardOrItemizedCalculate from '../../functions/standardOrItemizedCalculate';
import newYorkItemizedCalcFunction from '../../functions/newYorkItemizedCalcFunction';
import taxCalcFunction from '../../functions/taxCalcFunction';
import capitalLossLimitationFunction from '../../functions/capitalLossLimitationFunction';
import quarterFactorCalcFunction from '../../functions/quarterFactorCalcFunction';
import taxPaymentsRowForComp from '../../functions/taxPaymentsRowForComp';
import safeHarborTaxCalculate from '../../functions/safeHarborTaxCalculate';
import convertStringsToNumbers from '../../functions/convertStringsToNumbers';
import exportDataToCsv from '../../functions/exportDataToCsv';
import federalItemizedCalcFunction from '../../functions/federalItemizedCalcFunction';
import StandardOutput from './StandardOutput';
import ItemizedOutput from './ItemizedOutput';
import OptimizedStandardItemizedOutput from './OptimizedStandardItemizedOutput';
import StateColumnView from './StateColumnView';
import NumbersOutputTaxPaymentsRow from './NumbersOutputTaxPaymentsRow';
import { 
  INumbersInput, 
  INumbersInputAsNumbers, 
  IItemizedDeductionsCalcFederalAsNumbers,
  IItemizedDeductionsCalcNewYorkAsNumbers 
} from '../../types';
import {
  taxIncomeElements,
  taxAdjustmentElements,
  taxOtherFederal,
  taxOtherState,
  paymentsFederal,
  paymentsState,
} from '../../constants/generalConstants';
import { useAppSelector } from '../../../redux/hooks';
import {
  clientInfoSelector,
  taxAssumptionsSelector,
  numbersInputSelector,
  numbersAnnualizationSelector,
  safeHarborInputSelector,
} from '../../../redux/reducers/taxProjectionDataSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faFileCsv,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import './numbersOutput.scss';

interface Props {
  sideBarNavSummaryShow: boolean;
}

const NumbersOuput = (props: Props) => {
  const [hideUnusedRows, setHideUnusedRows] = useState(false);
  const clientInfoFromStore = useAppSelector(clientInfoSelector);
  const taxAssumptionsFromStore = useAppSelector(taxAssumptionsSelector);
  const quarterFromStore = taxAssumptionsFromStore.quarter;
  const dependentsFromStore = taxAssumptionsFromStore.dependents;
  const filingStatusFromStore = taxAssumptionsFromStore.filingStatus;
  const standardFromStore = taxAssumptionsFromStore.standard;
  const residencyFromStore = taxAssumptionsFromStore.residency;
  const safeHarborComparisonFromStore = taxAssumptionsFromStore.safeHarborComparison;
  const numbersInputFromStore = useAppSelector(numbersInputSelector);
  const numbersAnnualizationFromStore = useAppSelector(numbersAnnualizationSelector);
  const safeHarborInputFromStore = useAppSelector(safeHarborInputSelector);

  const numbersInputConvertedToNumbers: INumbersInputAsNumbers = convertStringsToNumbers(numbersInputFromStore);
  const annualizationFactor: number = annualizationFactorCalcFunction(quarterFromStore);
  const quarterFactor: number = quarterFactorCalcFunction(quarterFromStore);
  const taxInputDataAnnualized: INumbersInputAsNumbers = annualizeDataFunction(numbersInputConvertedToNumbers, numbersAnnualizationFromStore, annualizationFactor);
  const totalIncome: number = summationFunction(taxInputDataAnnualized, taxIncomeElements);
  const totalAdjustmentsBeforeSETaxDed: number = summationFunction(taxInputDataAnnualized, taxAdjustmentElements);
  const seTax: number = seTaxFunction(taxInputDataAnnualized['businessIncome'], taxInputDataAnnualized['wages']);
  const deductibleSETax: number = Math.round(seTax / -2);
  const capitalLossLimitation: number = capitalLossLimitationFunction(taxInputDataAnnualized['shortCapitalIncome'], taxInputDataAnnualized['longCapitalIncome']);
  const totalAdjustmentsAfterSETaxDed: number = totalAdjustmentsBeforeSETaxDed + deductibleSETax;
  const totalStateOther: number = summationFunction(taxInputDataAnnualized, taxOtherState);
  const federalAdjustedGrossIncome: number = totalIncome + totalAdjustmentsAfterSETaxDed + capitalLossLimitation;
  const stateAdjustedGrossIncome: number = totalIncome + totalAdjustmentsAfterSETaxDed + capitalLossLimitation + totalStateOther;
  
  // start itemized & standard calculations
  const totalFederalItemizedCalc: [number, IItemizedDeductionsCalcFederalAsNumbers] = federalItemizedCalcFunction(
    federalAdjustedGrossIncome,
    taxInputDataAnnualized,
    filingStatusFromStore);
  const totalFederalItemized: number = totalFederalItemizedCalc[0];
  const itemizedDeductionsCalcFederalValues: IItemizedDeductionsCalcFederalAsNumbers = totalFederalItemizedCalc[1];
  const totalStateItemizedCalc: [number, IItemizedDeductionsCalcNewYorkAsNumbers] = newYorkItemizedCalcFunction(
    stateAdjustedGrossIncome,
    taxInputDataAnnualized,
    filingStatusFromStore);
  const totalStateItemized: number = totalStateItemizedCalc[0];
  const itemizedDeductionsCalcStateValues: IItemizedDeductionsCalcNewYorkAsNumbers = totalStateItemizedCalc[1];
  const federalStandard: number = standardFunction('federal', filingStatusFromStore);
  const stateStandard: number = standardFunction('newYork', filingStatusFromStore);
  const federalStandardOrItemizedCalc: [number, string] = standardOrItemizedCalculate(standardFromStore, totalFederalItemized, federalStandard);
  const federalStandardOrItemizedValue: number = federalStandardOrItemizedCalc[0];
  const federalStandardOrItemizedOptimizedOption: string = federalStandardOrItemizedCalc[1];
  const stateStandardOrItemizedCalc: [number, string] = standardOrItemizedCalculate(standardFromStore, totalStateItemized, stateStandard);
  const stateStandardOrItemizedValue: number = stateStandardOrItemizedCalc[0];
  const stateStandardOrItemizedOptimizedOption: string = stateStandardOrItemizedCalc[1];
  // end itemized & standard calculations

  const totalFederalOther: number = summationFunction(taxInputDataAnnualized, taxOtherFederal);
  const stateDependentExemptions: number = parseInt(dependentsFromStore) * -1000;
  const federalTaxableIncome: number = federalAdjustedGrossIncome + federalStandardOrItemizedValue + totalFederalOther;
  const stateTaxableIncome: number = stateAdjustedGrossIncome + stateStandardOrItemizedValue + stateDependentExemptions;
  const federalOtherTaxes: number = taxInputDataAnnualized['otherFedTaxes'];
  const stateOtherTaxes: number = taxInputDataAnnualized['otherStateTaxes'];
  const federalChildTaxCredits: number = childTaxCreditFunction(federalAdjustedGrossIncome, dependentsFromStore, filingStatusFromStore);
  const federalTaxCalculated: number = Math.round(
    taxCalcFunction(
      federalTaxableIncome,
      filingStatusFromStore,
      'Federal',
      taxInputDataAnnualized['shortCapitalIncome'],
      taxInputDataAnnualized['longCapitalIncome'],
      taxInputDataAnnualized['qualifiedDividends']
    ) + seTax
  );
  const stateTaxCalculated: number = Math.round(taxCalcFunction(stateTaxableIncome, filingStatusFromStore, residencyFromStore));
  const totalFederalTax: number = Math.round(federalOtherTaxes + federalTaxCalculated - federalChildTaxCredits);
  const totalStateTax: number = Math.round(stateOtherTaxes + stateTaxCalculated);
  const totalFederalQuarterTax: number = Math.round(totalFederalTax * quarterFactor);
  const totalStateQuarterTax: number = Math.round(totalStateTax * quarterFactor);
  const totalFederalPayments = summationFunction(taxInputDataAnnualized,paymentsFederal);
  const totalStatePayments = summationFunction(taxInputDataAnnualized, paymentsState);
  const totalFedTaxDueOverpaid = totalFederalQuarterTax - totalFederalPayments;
  const totalStateTaxDueOverpaid = totalStateQuarterTax - totalStatePayments;
  const federalSafeHarbor: number =
    safeHarborComparisonFromStore === 'Yes'
      ? safeHarborTaxCalculate(
        safeHarborInputFromStore.federalSafeHarborPercentage,
        safeHarborInputFromStore.federalPriorYearTax,
        quarterFactor,
        totalFederalPayments
      )
      : 0;
  const stateSafeHarbor: number =
    safeHarborComparisonFromStore === 'Yes'
      ? safeHarborTaxCalculate(
        safeHarborInputFromStore.stateSafeHarborPercentage,
        safeHarborInputFromStore.statePriorYearTax,
        quarterFactor,
        totalStatePayments
      )
      : 0;
  const federalMinimumNumber: number =
    totalFedTaxDueOverpaid < federalSafeHarbor
      ? totalFedTaxDueOverpaid
      : federalSafeHarbor;
  const stateMinimumNumber: number =
    totalStateTaxDueOverpaid < stateSafeHarbor
      ? totalStateTaxDueOverpaid
      : stateSafeHarbor;
  const NA = 'X';

  // add commas to final data
  const taxInputDataAnnualizedWithCommas = addCommasToSetOfNumbers(taxInputDataAnnualized);

  // data specific for NumbersOutputTaxPaymentsRow
  const taxPaymentsRows = taxPaymentsRowForComp(taxInputDataAnnualizedWithCommas);

  return (
  // this component has some refactor opportunities

    <div id={props.sideBarNavSummaryShow ? 'numbers-output-container' : 'numbers-output-container-full-width'}>
      <header className="numbers-output-with-tax-box-title">
        <span>INCOME, DEDUCTION, AND TAX SUMMARY</span>
        <label htmlFor="toggle-hide-rows" className="toggle-hide-rows-label">
          Hide Rows
          <input
            type="checkbox"
            id="toggle-hide-rows"
            className="toggle-hide-rows-checkbox"
            checked={hideUnusedRows}
            onChange={() => setHideUnusedRows(!hideUnusedRows)}
          />
        </label>
      </header>
      <table className="numbers-output-table" id="numbers-output-table-container">
        <thead className="numbers-output-table-header">
          <tr>
            <th className='numbers-output-table-header-desc'>DESCRIPTION</th>
            <th>FEDERAL</th>
            {residencyFromStore !== 'None' ? <th>STATE</th> : null}
          </tr>
        </thead>
        <tbody>
          {taxIncomeElements
            .filter(
              (item) =>
                taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput] !== '0' || !hideUnusedRows
            )
            .map((item) => (
              <tr className="table-row-item-regular" key={item.id}>
                <th scope="row" className="table-description-item">
                  {item.element}
                </th>
                <td>
                  {taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput]}
                </td>
                {
                  <StateColumnView
                    residency={residencyFromStore}
                    nAValue={NA}
                    outputValue={taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput]}
                  />
                }
              </tr>
            ))}

          <tr className="table-total-row">
            <th scope="row">TOTAL INCOME</th>
            <td>{addCommasToSingleNumbers(totalIncome)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(totalIncome)}
              />
            }
          </tr>
          <tr className="table-spacing-row">
            <td>&nbsp;</td>
          </tr>
        
          {taxAdjustmentElements
            .filter(
              (item) =>
                taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput] !== '0' || !hideUnusedRows
            )
            .map((item) => (
              <tr className="table-row-item-regular" key={item.id}>
                <th scope="row" className="table-description-item">
                  {item.element}
                </th>
                <td>
                  {
                    taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput]
                  }
                </td>
                {
                  <StateColumnView
                    residency={residencyFromStore}
                    nAValue={NA}
                    outputValue={taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput]}
                  />
                }
              </tr>
            ))}

          {addCommasToSingleNumbers(deductibleSETax) !== '0' ||
          !hideUnusedRows ? (
              <tr className="table-row-item-regular">
                <th scope="row" className="table-description-item">
                Deductible S/E Tax
                </th>
                <td>{addCommasToSingleNumbers(deductibleSETax)}</td>
                {
                  <StateColumnView
                    residency={residencyFromStore}
                    nAValue={NA}
                    outputValue={addCommasToSingleNumbers(deductibleSETax)}
                  />
                }
              </tr>
            ) : null}

          <tr className="table-total-row">
            <th scope="row">TOTAL ADJUSTMENTS</th>
            <td>{addCommasToSingleNumbers(totalAdjustmentsAfterSETaxDed)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(
                  totalAdjustmentsAfterSETaxDed
                )}
              />
            }
          </tr>
          <tr className="table-spacing-row">
            <td>&nbsp;</td>
          </tr>

          {taxOtherState
            .filter(
              (item) =>
                taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput] !== '0' || !hideUnusedRows
            )
            .map((item) => (
              <tr className="table-row-item-regular" key={item.id}>
                <th scope="row" className="table-description-item">
                  {item.element}
                </th>
                <td className="table-na-section">{NA}</td>
                {
                  <StateColumnView
                    residency={residencyFromStore}
                    nAValue={NA}
                    outputValue={taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput]}
                  />
                }
              </tr>
            ))}

          {addCommasToSingleNumbers(totalStateOther) !== '0' ||
          !hideUnusedRows ? (
              <tr className="table-total-row">
                <th scope="row">TOTAL STATE OTHER</th>
                <td className="table-na-section">{NA}</td>
                {
                  <StateColumnView
                    residency={residencyFromStore}
                    nAValue={NA}
                    outputValue={addCommasToSingleNumbers(totalStateOther)}
                  />
                }
              </tr>
            ) : null}
          { addCommasToSingleNumbers(totalStateOther) !== '0' || !hideUnusedRows ? (
            <tr className="table-spacing-row">
              <td>&nbsp;</td>
            </tr>
          ) : null}

          <tr className="table-row-item-regular">
            <th scope="row" className="table-description-item">
              Capital Loss Limitation
            </th>
            <td>{addCommasToSingleNumbers(capitalLossLimitation)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(capitalLossLimitation)}
              />
            }
          </tr>

          <tr className="table-total-row">
            <th scope="row">ADJUSTED GROSS INCOME</th>
            <td>{addCommasToSingleNumbers(federalAdjustedGrossIncome)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(stateAdjustedGrossIncome)}
              />
            }
          </tr>
          <tr className="table-spacing-row">
            <td>&nbsp;</td>
          </tr>
        </tbody>
        {standardFromStore === 'Standard' ? (
          <StandardOutput
            federalStandard={addCommasToSingleNumbers(federalStandard)}
            stateStandard={addCommasToSingleNumbers(stateStandard)}
            NA={NA}
            residencyFromStore={residencyFromStore}
          />
        ) : standardFromStore === 'Itemized' ? (
          <ItemizedOutput
            itemizedDeductionsCalcFederalValues={addCommasToSetOfNumbers(itemizedDeductionsCalcFederalValues)}
            itemizedDeductionsCalcStateValues={addCommasToSetOfNumbers(itemizedDeductionsCalcStateValues)}
            totalFederalItemized={addCommasToSingleNumbers(totalFederalItemized)}
            totalStateItemized={addCommasToSingleNumbers(totalStateItemized)}
            NA={NA}
            residencyFromStore={residencyFromStore}
            hideUnusedRows={hideUnusedRows}
          />
        ) : 
          <OptimizedStandardItemizedOutput
            federalOptimizedOption={federalStandardOrItemizedOptimizedOption}
            stateOptimizedOption={stateStandardOrItemizedOptimizedOption}
            federalStandard={addCommasToSingleNumbers(federalStandard)}
            stateStandard={addCommasToSingleNumbers(stateStandard)}
            NA={NA}
            residencyFromStore={residencyFromStore}
            itemizedDeductionsCalcFederalValues={addCommasToSetOfNumbers(itemizedDeductionsCalcFederalValues)}
            itemizedDeductionsCalcStateValues={addCommasToSetOfNumbers(itemizedDeductionsCalcStateValues)}
            totalFederalItemized={addCommasToSingleNumbers(totalFederalItemized)}
            totalStateItemized={addCommasToSingleNumbers(totalStateItemized)}
            hideUnusedRows={hideUnusedRows}
          />
        }
        <tbody style={{ borderTop: 'none' }}>
          {taxOtherFederal
            .filter(
              (item) =>
                taxInputDataAnnualizedWithCommas[
                  item.hardValue as keyof INumbersInput
                ] !== '0' || !hideUnusedRows
            )
            .map((item) => (
              <tr className="table-row-item-regular" key={item.id}>
                <th scope="row" className="table-description-item">{item.element}</th>
                <td>
                  {taxInputDataAnnualizedWithCommas[item.hardValue as keyof INumbersInput]}
                </td>
                {residencyFromStore !== 'None' ? (
                  <td className="table-na-section">{NA}</td>
                ) : null}
              </tr>
            ))}

          {addCommasToSingleNumbers(totalFederalOther) !== '0' ||
          !hideUnusedRows ? (
              <tr className="table-total-row">
                <th scope="row">TOTAL FEDERAL OTHER</th>
                <td>{addCommasToSingleNumbers(totalFederalOther)}</td>
                {residencyFromStore !== 'None' ? (
                  <td className="table-na-section">{NA}</td>
                ) : null}
              </tr>
            ) : null}
          {addCommasToSingleNumbers(totalFederalOther) !== '0' ||
          !hideUnusedRows ? (
              <tr className="table-spacing-row">
                <td>&nbsp;</td>
              </tr>
            ) : null}

          <tr className="table-row-item-regular">
            <th scope="row" className="table-description-item">
              State Dependent Exemptions
            </th>
            <td className="table-na-section">{NA}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(stateDependentExemptions)}
              />
            }
          </tr>

          <tr className="table-total-row">
            <th scope="row">TAXABLE INCOME</th>
            <td>{addCommasToSingleNumbers(federalTaxableIncome)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(stateTaxableIncome)}
              />
            }
          </tr>
  
          <tr className="table-total-row">
            <th scope="row">TOTAL CALCULATED TAX</th>
            <td>{addCommasToSingleNumbers(federalTaxCalculated)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(stateTaxCalculated)}
              />
            }
          </tr>
          <tr className="table-spacing-row">
            <td>&nbsp;</td>
          </tr>

          <tr className="table-row-item-regular">
            <th scope="row" className="table-description-item">
              Total Other Tax
            </th>
            <td>{addCommasToSingleNumbers(federalOtherTaxes)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(stateOtherTaxes)}
              />
            }
          </tr>

          <tr className="table-row-item-regular">
            <th scope="row" className="table-description-item">
              Federal Child Tax Credits
            </th>
            <td>{addCommasToSingleNumbers(federalChildTaxCredits)}</td>
            {residencyFromStore !== 'None' ? (
              <td className="table-na-section">{NA}</td>
            ) : null}
          </tr>

          <tr className="table-total-row">
            <th scope="row">TOTAL ALL TAX</th>
            <td>{addCommasToSingleNumbers(totalFederalTax)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(totalStateTax)}
              />
            }
          </tr>

          <tr className="table-total-row">
            <th scope="row">TOTAL QUARTERLY TAX DUE</th>
            <td>{addCommasToSingleNumbers(totalFederalQuarterTax)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(totalStateQuarterTax)}
              />
            }
          </tr>
          <tr className="table-spacing-row">
            <td>&nbsp;</td>
          </tr>

          {taxPaymentsRows.map((row) => (
            <NumbersOutputTaxPaymentsRow
              key={row.index}
              description={row.descriptionElement}
              federalValue={row.federalValue}
              stateValue={row.stateValue}
              residency={residencyFromStore}
              hideRow={hideUnusedRows}
            />
          ))}

          <tr className="table-total-row">
            <th scope="row">TOTAL PAYMENTS</th>
            <td>{addCommasToSingleNumbers(totalFederalPayments)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(totalStatePayments)}
              />
            }
          </tr>

          <tr className="table-total-row">
            <th scope="row">TOTAL TAX DUE /(OVERPAID)</th>
            <td>{addCommasToSingleNumbers(totalFedTaxDueOverpaid)}</td>
            {
              <StateColumnView
                residency={residencyFromStore}
                nAValue={NA}
                outputValue={addCommasToSingleNumbers(totalStateTaxDueOverpaid)}
              />
            }
          </tr>

          {safeHarborComparisonFromStore === 'Yes' ? (
            <tr className="table-total-row" id="table-safe-harbor-row">
              <th scope="row">SAFE HARBOR TAX DUE /(OVERPAID)</th>
              <td>{addCommasToSingleNumbers(federalSafeHarbor)}</td>
              {
                <StateColumnView
                  residency={residencyFromStore}
                  nAValue={NA}
                  outputValue={addCommasToSingleNumbers(stateSafeHarbor)}
                />
              }
            </tr>
          ) : null}

          {safeHarborComparisonFromStore === 'Yes' ? (
            <tr className="table-total-row" id="table-minimum-row">
              <th scope="row">MINIMUM TAX DUE /(OVERPAID)</th>
              <td>{addCommasToSingleNumbers(federalMinimumNumber)}</td>
              {
                <StateColumnView
                  residency={residencyFromStore}
                  nAValue={NA}
                  outputValue={addCommasToSingleNumbers(stateMinimumNumber)}
                />
              }
            </tr>
          ) : null}
        </tbody>
      </table>
      <footer className="export-footer-container">
        <div className="export-footer-wrapper">
          <div className="export-footer-icon-container">
            <Tooltip title="Print">
              <button onClick={() => window.print()}>
                <FontAwesomeIcon icon={faPrint} />
              </button>
            </Tooltip>
          </div>
          <div className="export-footer-icon-container">
            <Tooltip title="Export to CSV">
              <button
                onClick={() =>
                  exportDataToCsv({
                    clientInfoFromStore, 
                    taxAssumptionsFromStore, 
                    taxInputDataAnnualized, 
                    totalIncome, 
                    totalAdjustmentsAfterSETaxDed,
                    deductibleSETax,
                    totalStateOther,
                    capitalLossLimitation,
                    federalAdjustedGrossIncome,
                    stateAdjustedGrossIncome,
                    federalStandard,
                    stateStandard,
                    itemizedDeductionsCalcFederalValues,
                    itemizedDeductionsCalcStateValues,
                    totalFederalItemized,
                    totalStateItemized,
                    totalFederalOther,
                    stateDependentExemptions,
                    federalTaxableIncome,
                    stateTaxableIncome,
                    federalTaxCalculated,
                    stateTaxCalculated,
                    federalOtherTaxes,
                    stateOtherTaxes,
                    federalChildTaxCredits,
                    totalFederalTax,
                    totalStateTax,
                    totalFederalQuarterTax,
                    totalStateQuarterTax,
                    totalFederalPayments,
                    totalStatePayments,
                    totalFedTaxDueOverpaid,
                    totalStateTaxDueOverpaid,
                    federalSafeHarbor,
                    stateSafeHarbor,
                    federalMinimumNumber,
                    stateMinimumNumber
                  })
                }
              >
                <FontAwesomeIcon icon={faFileCsv} />
              </button>
            </Tooltip>
          </div>
          {/* To be implemented at a later date */}
          {/* <div className="export-footer-icon-container">
            <Tooltip title="Export to Excel">
              <button>
                <FontAwesomeIcon icon={faFileExcel} />
              </button>
            </Tooltip>
          </div> */}
        </div>
      </footer>
    </div>
  );
};

export default NumbersOuput;
