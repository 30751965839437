const standardOrItemizedCalculate = (standardOrItemized: string, totalItemized: number, totalStandard: number): [number, string] => {
  let total = 0;
  let greaterNumber = 0;
  let optimizedOption: string;

  if (Math.abs(totalItemized) > Math.abs(totalStandard)) {
    optimizedOption = 'Itemized';
    greaterNumber = totalItemized;
  }
  else {
    optimizedOption = 'Standard';
    greaterNumber = totalStandard;
  }

  switch (standardOrItemized) {
    case 'Standard':
      total = totalStandard;
      optimizedOption = standardOrItemized;
      break;
    case 'Itemized':
      total = totalItemized;
      optimizedOption = standardOrItemized;
      break;
    case 'Optimize':
      total = greaterNumber;
      break;
  }

  return [total, optimizedOption];
};
  
export default standardOrItemizedCalculate;
