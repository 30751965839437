import { useState, ChangeEvent, FormEvent } from 'react';
import { IClientInfo } from '../../types';
import { clientInfoDefault } from '../../constants/generalConstants';
import './loginDropDown.scss';

interface DataExchangeBoxProps {
  isOpen: boolean;
  methodType: string;
  handleSubmit: (arg1: FormEvent<HTMLFormElement>, arg2: string) => void;
  handleCancel: () => void;
}

const DataExchangeBox = ({
  isOpen,
  methodType,
  handleSubmit,
  handleCancel
}: DataExchangeBoxProps
) => {

  const [data, setData] = useState<IClientInfo>(clientInfoDefault);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevLoadData) => ({ ...prevLoadData, [name]: value }));
  };

  if (!isOpen) {
    return null;
  }
  else {
    return (
      <div className="data-exchange-box-overlay">
        <div className="data-exchange-box">
          <form onSubmit={(event) => handleSubmit(event, methodType)}>
            <div className="data-exchange-box-group">
              <label htmlFor="clientName" className='data-exchange-box-label'>
                ClientName:&nbsp;
                <input
                  type="text"
                  className="data-exchange-box-input"
                  name="clientName"
                  value={data.clientName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label htmlFor="clientId" className='data-exchange-box-label'>
                ClientId:&nbsp;
                <input
                  type="text"
                  className="data-exchange-box-input"
                  name="clientId"
                  value={data.clientId}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div id="data-exchange-group-buttons" className="login-drop-down-button-group">
              <div className={methodType === 'DELETE' ? 'login-drop-down-button-group-delete' : 'login-drop-down-button-group-load'}>
                <button type="submit">{methodType}</button>
              </div>
              <div className="login-drop-down-button-group-cancel">
                <button type="button" onClick={handleCancel}>CANCEL</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default DataExchangeBox;
