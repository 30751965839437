import { useEffect, useRef, useState, ChangeEvent } from 'react';
import { clientInfoAction, clientInfoSelector } from '../../redux/reducers/taxProjectionDataSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import DbStatusX from './DbStatusX';
import './clientTitleBox.scss';

interface Props {
  sideBarNavSummaryShow: boolean;
}

const ClientTitleBox = (props: Props) => {

  // temporary until I can find better backend hosting or pay for current backend hosting ---below
  const productionApiBaseUrl = process.env.REACT_APP_PRODUCTIONAPIBASEURL;
  const apiBaseUrl = process.env.NODE_ENV === 'production' ? productionApiBaseUrl : 'http://localhost:3001';
  // temporary until I can find better backend hosting or pay for current backend hosting ---above
  const clientInfoFromStore = useAppSelector(clientInfoSelector);
  const dispatch = useAppDispatch();
  const [clientInfoState, setClientInfoState] = useState(clientInfoFromStore);
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setClientInfoState(clientInfoFromStore);
  }, [clientInfoFromStore]);

  useEffect(() => {
    inputEl.current?.focus();
  }, []);

  const handleValueChange = (name: string) => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const oldValues = clientInfoState;
      const newValues = { ...oldValues, [name]: value };
      dispatch(clientInfoAction(newValues));
      setClientInfoState(newValues);
    };
  };

  return (
    <div>
      <div id={props.sideBarNavSummaryShow ? 'client-title-box' : 'client-title-box-full-width'}>
        <div id="client-title-box-grid">
          <div className="client-title-box-grid-item">
            <label className="client-title-box-label" htmlFor="clientName">
              NAME:&nbsp;
            </label>
            <input
              onChange={handleValueChange('clientName')}
              value={clientInfoState.clientName}
              ref={inputEl}
              type="text"
              className="client-input-box"
              id="clientName"
              name="clientName"
            />
          </div>
          <div className="client-title-box-grid-item">
            <label className={'client-title-box-label'} htmlFor="clientId">
              ID:&nbsp;
            </label>
            <input
              onChange={handleValueChange('clientId')}
              value={clientInfoState.clientId}
              type="text"
              className="client-input-box"
              id="clientId"
              name="clientId"
            />
          </div>
        </div>
        {/* temporary until I can find better backend hosting or pay for current backend hosting */}
        <DbStatusX url={apiBaseUrl} />
        {/* temporary until I can find better backend hosting or pay for current backend hosting */}
      </div>
    </div>
  );
};

export default ClientTitleBox;
