import {
  numbersInputDefault,
  numbersAnnualizationDefault,
  taxIncomeElements,
  taxAdjustmentElements,
  taxItemizedDeductionElements,
  taxStateItemizedDeductionElements,
  taxOtherFederal,
  taxOtherState,
  paymentsFederal,
  paymentsState,
  otherTaxes,
} from '../../constants/generalConstants';
import NumbersInputComponent from './NumbersInputComponent';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  numbersInputAction,
  numbersAnnualizationAction,
  numbersInputSelector,
  numbersAnnualizationSelector
} from '../../../redux/reducers/taxProjectionDataSlice';
import runNumberCheck from '../../functions/runNumberCheck';
import { IInputRefs, INumbersAnnualization, INumbersInputChangeHandler } from '../../types';
import './numbersInput.scss';

interface Props {
  refs: IInputRefs;
  sideBarNavSummaryShow: boolean;
}

const NumbersInput = (props: Props) => {
  const numbersInputFromStore = useAppSelector(numbersInputSelector);
  const numbersAnnualizationFromStore = useAppSelector(
    numbersAnnualizationSelector
  );
  const dispatch = useAppDispatch();

  const handleNumbersInputChange: INumbersInputChangeHandler = (
    e,
    numberCheckFunction,
    sign
  ) => {
    const { name, value } = e.target;
    const newValue = numberCheckFunction(value, sign);
    const oldNumbersInputState = numbersInputFromStore;
    const newNumbersInputState = {
      ...oldNumbersInputState,
      [name]: newValue,
    };
    dispatch(numbersInputAction(newNumbersInputState));
  };

  const handleAnnualizationButton = (
    value: string,
    description: string,
    numbersAnnualizationObject: INumbersAnnualization
  ) => {
    const newNumbersAnnualizationState = {
      ...numbersAnnualizationObject,
      [description]: value === 'on' ? 'off' : 'on',
    };
    dispatch(numbersAnnualizationAction(newNumbersAnnualizationState));
  };

  const handleReset = () => {
    dispatch(numbersInputAction(numbersInputDefault));
    dispatch(numbersAnnualizationAction(numbersAnnualizationDefault));
  };

  return (
    <div>
      <div id={props.sideBarNavSummaryShow ? 'numbers-input-box' : 'numbers-input-box-full-width'}>
        <header className="box-title" ref={props.refs.inputIncomeRef}>INPUT NUMBERS</header>
        <h6 className="box-sub-title">
          All numbers should be input as year to date by quarter; Click&nbsp; 
          <span className='annualize-input-button-example quarterly'>&nbsp;&nbsp;QTR&nbsp;&nbsp;</span> 
          &nbsp;or&nbsp;
          <span className='annualize-input-button-example'>&nbsp;&nbsp;YEAR&nbsp;&nbsp;</span> 
          &nbsp;to switch between quarterly &#40;annualized&#41; and yearly.
        </h6>
        {/* elementData are constants */}
        <NumbersInputComponent
          elementData={taxIncomeElements}
          title={'INCOME'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'ALL'}
          handleAnnualizationButton={handleAnnualizationButton}
        />
        <NumbersInputComponent
          elementData={taxAdjustmentElements}
          title={'ADJUSTMENTS (NEGATIVE)'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'NEGATIVE'}
          handleAnnualizationButton={handleAnnualizationButton}
          ref={props.refs.inputAdjustmentsRef}
        />
        <NumbersInputComponent
          elementData={taxItemizedDeductionElements}
          elementData2={taxStateItemizedDeductionElements}
          title={'ITEMIZED DEDUCTIONS (NEGATIVE) *(SOFTWARE WILL LIMIT SOME NUMBERS)(SL)'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'NEGATIVE'}
          handleAnnualizationButton={handleAnnualizationButton}
          ref={props.refs.inputItemizedRef}
        />
        <NumbersInputComponent
          elementData={taxOtherFederal}
          title={'FEDERAL OTHER'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'ALL'}
          handleAnnualizationButton={handleAnnualizationButton}
          ref={props.refs.inputOtherRef}
        />
        <NumbersInputComponent
          elementData={taxOtherState}
          title={'STATE OTHER'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'ALL'}
          handleAnnualizationButton={handleAnnualizationButton}
        />
        <NumbersInputComponent
          elementData={otherTaxes}
          title={'OTHER TAXES'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'ALL'}
          handleAnnualizationButton={handleAnnualizationButton}
        />
        <NumbersInputComponent
          elementData={paymentsFederal}
          title={'FEDERAL TAX PAYMENTS'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'POSITIVE'}
          handleAnnualizationButton={handleAnnualizationButton}
          ref={props.refs.inputFedPmtsRef}
        />
        <NumbersInputComponent
          elementData={paymentsState}
          title={'STATE TAX PAYMENTS'}
          numbersInputDataValues={numbersInputFromStore}
          numbersAnnualizationDataValues={numbersAnnualizationFromStore}
          handleInputChange={handleNumbersInputChange}
          numberCheckFunction={runNumberCheck}
          sign={'POSITIVE'}
          handleAnnualizationButton={handleAnnualizationButton}
          ref={props.refs.inputStatePmtsRef}
        />

        <div
          id="flex-container-input-numbers-submit-reset-buttons"
          className="flex-container-reset-button"
        >
          <button
            id="numbers-input-reset-button"
            className="reset-button"
            onClick={handleReset}
          >
            RESET
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumbersInput;
