import { taxNotesAction, taxNotesSelector } from '../../redux/reducers/taxProjectionDataSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import './taxNotes.scss';

interface Props {
  sideBarNavSummaryShow: boolean;
}

const TaxNotes = (props: Props) => {

  const taxNotesFromStore = useAppSelector(taxNotesSelector);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newStringValue = e.target.value;
    dispatch(taxNotesAction(newStringValue));
  };

  return (
    <div id={props.sideBarNavSummaryShow ? 'taxnotes-container' : 'taxnotes-container-full-width'}>
      <div id="taxnotes-title">
        <header>NOTES</header>
      </div>
      <form id="taxnotes-form">
        <textarea 
          id="taxnotes-textarea" 
          placeholder="Type Your Tax Notes Here..."
          value={taxNotesFromStore}
          onChange={handleChange}
        >
        </textarea>
      </form>
    </div>
  );
};

export default TaxNotes;
