const enterSmallerNumberFunction = (number1: number, number2: number): number => {

  if (number1 <= number2) {
    return number1;
  }
  else {
    return number2;
  }
};

export default enterSmallerNumberFunction;
