import { createTheme } from '@mui/material/styles';

export const buttonTheme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: 'white',
          background: 'darkBlue',
          '&:hover': {
            background: 'green',
          },
          padding: '7px',
          fontSize: '18px',
        },
      },
    },
  },
});

export const buttonThemeLoggedIn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          background: 'darkBlue',
          padding: '7px',
          fontSize: '18px',
          transition: '0.3s',
          '&:hover': {
            background: 'green',
            transform: 'scale(1.1)',
            boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
          },
        },
      },
    },
  }
});
