import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@mui/material/';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LoginDropDown from './LoginDropDown/LoginDropDown';
import { useState } from 'react';
import './header.scss';
import { Link } from 'react-router-dom';

interface Props {
  showTitle: boolean;
  window?: () => Window;
}

type NavItem = {
  id: number;
  description: string;
  link: string;
};

const Header = (props: Props) => {
  const drawerWidth = 240;
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navItems: NavItem[] = [
    { id: 0, description: 'Documentation', link: 'https://github.com/russelltheprogrammer/tax-projection-individual-docs#readme' },
    { id: 1, description: 'Contact', link: '#footerContact' },
  ];
  const title = 'TAXPROJECTION.COM';

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography
        variant="h6"
        style={{ fontWeight: 600 }}
        sx={{ my: 2 }}>
        {title}
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ textAlign: 'center' }}>
          <div className="header-list-anchor-container">
            <Link to={`/`} className="header-list-anchor">
              Home
            </Link>
          </div>
        </ListItem>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding sx={{ textAlign: 'center' }}>
            <div className="header-list-anchor-container" key={item.id}>
              <a
                className="header-list-anchor"
                target={item.description === 'Documentation' ? '_blank' : undefined}
                rel="noreferrer"
                href={item.link}
              >
                {item.description}
              </a>
            </div>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box>
        <AppBar
          component="nav"
          position="relative"
          sx={{
            background: 'linear-gradient(to left, #57a1f8, #0047ab)',
            py: '1%',
            backgroundColor: '#0047ab',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'block', sm: 'block' },
              }}
            >
              <Typography
                style={{ fontWeight: 600 }}
                sx={{
                  typography: { xs: 'h5', sm: 'h5' },
                  '@media (max-width: 464px)': {
                    fontSize: '16px',
                  },
                }}
              >
                {!props.showTitle || isMediumScreen ? title : null}
              </Typography>
            </Typography>
            <Box
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block' },
              }}
            >
              <Link to={`/`} className="header-anchor">
                HOME
              </Link>
              {navItems.map((item) => (
                <a
                  className="header-anchor"
                  target={item.description === 'Documentation' ? '_blank' : undefined}
                  rel="noreferrer"
                  key={item.id}
                  href={item.link}
                >
                  {item.description.toUpperCase()}
                </a>
              ))}
            </Box>
            <Box>
              <LoginDropDown />
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default Header;
