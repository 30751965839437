import {
  federalSingleTaxBrackets, 
  federalMFJTaxBrackets, 
  federalMFSTaxBrackets, 
  federalHOHTaxBrackets, 
  newYorkStateSingleTaxBrackets,
  newYorkStateMFJTaxBrackets, 
  newYorkStateMFSTaxBrackets, 
  newYorkStateHOHTaxBrackets, 
  newYorkCitySingleTaxBrackets, 
  newYorkCityMFJTaxBrackets,
  newYorkCityMFSTaxBrackets, 
  newYorkCityHOHTaxBrackets
}
  from '../constants/generalConstants';
import taxBracketTaxFunction from './taxBracketTaxFunction';
import schDTaxWorksheetLineThresholdCalculation from './schDTaxWorksheetLineThresholdCalculation';
import enterSmallerNumberFunction from './enterSmallerNumberFunction';
import { ITaxBracketModel } from '../types';

const taxCalcFunction = (
  taxableIncome: number,
  filingStatus: string,
  residency: string,
  shortTermCapital = 0,
  longTermCapital = 0,
  qualifiedDividends = 0
): number => {

  let taxBracket: ITaxBracketModel[] | undefined = undefined;
  let taxBracket2: ITaxBracketModel[] | undefined = undefined;
  const expr: string = filingStatus + ' & ' + residency;
  let calculatedTax = 0;

  // refactor capital gains into its own function?
  // Sch D Line 1 is taxableIncome
  // Sch D Line 2 is qualifiedDividends
  const totalCapitalGains: number = shortTermCapital + longTermCapital;
  let schDLineThree = 0;
  let capitalGainsPlusQualifiedDividends = 0; // Sch D Line 4
  let taxableIncomeLessQualDivAndCapGains = 0; // Sch D Line 5
  const schDLineSixThreshold: number = schDTaxWorksheetLineThresholdCalculation(filingStatus, 'SchDLineSix');
  let schDLineSeven = 0;
  let schDLineEight = 0;
  let schDLineNine = 0;
  let schDLineTen = 0;
  const schDLineEleven: number = schDLineNine;
  let schDLineTwelve = 0;
  const schDLineThirteenThreshold: number = schDTaxWorksheetLineThresholdCalculation(filingStatus, 'SchDLineThirteen');
  let schDLineFourteen = 0;
  let schDLineFifteen = 0;
  let schDLineSixteen = 0;
  let schDLineSeventeen = 0;
  let schDLineEighteen = 0;
  let schDLineNineteen = 0;
  let schDLineTwenty = 0;
  let schDLineTwentyOne = 0;
  let calculatedTaxOfTaxableIncomeLessQualDivAndCapGains = 0; // Schd D Line 22
  let totalCapitalGainsTax = 0; // Sch D Line 23 Part 1
  let totalCalculatedTaxBenefittedFromCapGainsRate = 0; // Sch D Line 23 Part 2 TOTAL

  if (taxableIncome < 1) {
    return calculatedTax;
  }
  else if (residency === 'None') {
    return calculatedTax;
  }
  else if (residency === 'Federal') {
    if (longTermCapital >= totalCapitalGains) {
      if (totalCapitalGains <= 0) {
        schDLineThree = 0;
      }
      else {
        schDLineThree = totalCapitalGains;
      }
    }
    else {
      if (longTermCapital <= 0) {
        schDLineThree = 0;
      }
      else {
        schDLineThree = longTermCapital;
      }
    }

    capitalGainsPlusQualifiedDividends += schDLineThree + qualifiedDividends;
    taxableIncomeLessQualDivAndCapGains = taxableIncome - capitalGainsPlusQualifiedDividends;
    schDLineSeven = enterSmallerNumberFunction(schDLineSixThreshold, taxableIncome);
    schDLineEight = enterSmallerNumberFunction(taxableIncomeLessQualDivAndCapGains, schDLineSeven);
    schDLineNine = schDLineSeven - schDLineEight;
    schDLineTen = enterSmallerNumberFunction(taxableIncome, capitalGainsPlusQualifiedDividends);
    schDLineTwelve = schDLineTen - schDLineEleven;
    schDLineFourteen = enterSmallerNumberFunction(taxableIncome, schDLineThirteenThreshold);
    schDLineFifteen += taxableIncomeLessQualDivAndCapGains + schDLineNine;
    schDLineSixteen = schDLineFourteen - schDLineFifteen;

    if (schDLineSixteen < 1) {
      schDLineSixteen = 0;
    }

    schDLineSeventeen = enterSmallerNumberFunction(schDLineTwelve, schDLineSixteen);
    schDLineEighteen = Math.round(schDLineSeventeen * .15);
    schDLineNineteen += schDLineNine + schDLineSeventeen;
    schDLineTwenty = schDLineTen - schDLineNineteen;
    schDLineTwentyOne = Math.round(schDLineTwenty * .20);
    totalCapitalGainsTax += schDLineEighteen + schDLineTwentyOne;
  }

  switch (expr) {
    case 'Single & Federal':
      taxBracket = federalSingleTaxBrackets;
      break;
    case 'MFJ & Federal':
      taxBracket = federalMFJTaxBrackets;
      break;
    case 'MFS & Federal':
      taxBracket = federalMFSTaxBrackets;
      break;
    case 'HOH & Federal':
      taxBracket = federalHOHTaxBrackets;
      break;
    case 'Single & New York':
      taxBracket = newYorkStateSingleTaxBrackets;
      break;
    case 'MFJ & New York':
      taxBracket = newYorkStateMFJTaxBrackets;
      break;
    case 'MFS & New York':
      taxBracket = newYorkStateMFSTaxBrackets;
      break;
    case 'HOH & New York':
      taxBracket = newYorkStateHOHTaxBrackets;
      break;
    case 'Single & New York City':
      taxBracket = newYorkStateSingleTaxBrackets;
      taxBracket2 = newYorkCitySingleTaxBrackets;
      break;
    case 'MFJ & New York City':
      taxBracket = newYorkStateMFJTaxBrackets;
      taxBracket2 = newYorkCityMFJTaxBrackets;
      break;
    case 'MFS & New York City':
      taxBracket = newYorkStateMFSTaxBrackets;
      taxBracket2 = newYorkCityMFSTaxBrackets;
      break;
    case 'HOH & New York City':
      taxBracket = newYorkStateHOHTaxBrackets;
      taxBracket2 = newYorkCityHOHTaxBrackets;
      break;
    default:
      console.log('no state selected, there is an erorr in the taxCalcfunction');
      return 0;
  }

  calculatedTax += taxBracketTaxFunction(taxBracket, taxableIncome);

  if (residency === 'Federal') {
    calculatedTaxOfTaxableIncomeLessQualDivAndCapGains += taxBracketTaxFunction(taxBracket, taxableIncomeLessQualDivAndCapGains);
  }

  totalCalculatedTaxBenefittedFromCapGainsRate += totalCapitalGainsTax + calculatedTaxOfTaxableIncomeLessQualDivAndCapGains;

  if (residency === 'Federal') {
    calculatedTax = enterSmallerNumberFunction(calculatedTax, totalCalculatedTaxBenefittedFromCapGainsRate);
  }

  if (taxBracket2 === undefined) {
    return calculatedTax;
  }
  else if (taxBracket2 !== undefined) {
    calculatedTax += taxBracketTaxFunction(taxBracket2, taxableIncome);
    return calculatedTax;
  }
  console.log('No tax calculated, there is an error in the the taxCalcFunction');
  return 0;
};

export default taxCalcFunction;
