import { INumbersCheckFunction } from '../types';

const runNumberCheck: INumbersCheckFunction = (
  numberInput,
  sign
) => {
  const newNumber: string = numberInput;
  let checkNumberFunction: (input: string) => string = cleanUpNumbersInputStrings;

  if (sign === 'ALL') {
    checkNumberFunction = cleanUpNumbersInputStrings;
  }
  if (sign === 'NEGATIVE') {
    checkNumberFunction = cleanUpNegativeInputStrings;
  }
  if (sign === 'POSITIVE') {
    checkNumberFunction = cleanUpPositiveInputStrings;
  }

  const convertedNumber: string = checkNumberFunction(newNumber);
  
  return convertedNumber;
};

export default runNumberCheck;

const cleanUpNumbersInputStrings = (value: string): string => {
  let newValue = value;

  // Remove all non-numeric characters except '-'
  newValue = newValue.replace(/[^0-9-]/g, '');

  // If '-' is not at the beginning, remove it
  newValue =
    newValue[0] === '-'
      ? '-' + newValue.slice(1).replace(/-/g, '')
      : newValue.replace(/-/g, '');

  // If it's "-0", make it "0"
  if (newValue === '-0') {
    newValue = '0';
  }

  // If it's '0' or '-0' followed by one or more zeros, reduce to a single '0'
  newValue = newValue.replace(/^(-)?0+/, '0');

  // If it's not a single '0', remove leading zeros
  if (newValue !== '0') {
    newValue = newValue.replace(/^-?0+/, '');
  }

  return newValue;
};

const cleanUpNegativeInputStrings = (value: string): string => {
  let newValue = value;

  // Remove all non-numeric characters except '-'
  newValue = newValue.replace(/[^0-9-]/g, '');

  // If '-' is not at the beginning, move it there
  newValue =
    newValue[0] === '-'
      ? '-' + newValue.slice(1).replace(/-/g, '')
      : '-' + newValue.replace(/-/g, '');

  // If it's "--0", make it "0"
  if (newValue === '--0') {
    newValue = '0';
  }

  // If it's '0' or '0' followed by one or more zeros, reduce to a single '0'
  newValue = newValue.replace(/^(-)?0+/, '0');

  // If it's not a single '0', remove leading zeros and ensure it's negative
  if (newValue !== '0') {
    newValue = newValue.replace(/^0+/, '');
    if (newValue[0] !== '-') {
      newValue = '-' + newValue;
    }
  }

  return newValue;
};

const cleanUpPositiveInputStrings = (value: string): string => {
  let newValue = value;

  // Remove all non-numeric characters
  newValue = newValue.replace(/[^0-9]/g, '');

  // If it's '0' or '0' followed by one or more zeros, reduce to a single '0'
  newValue = newValue.replace(/^0+/, '0');

  // If it's not a single '0', remove leading zeros
  if (newValue !== '0') {
    newValue = newValue.replace(/^0+/, '');
  }

  return newValue;
};
