import * as React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { taxAssumptionsSelector } from '../../../redux/reducers/taxProjectionDataSlice';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { titleTheme, listThemeOne, listThemeTwo } from './sideBarNavSummaryThemes';
import { IInputRefs } from '../../types';
import { OnClickFunction, ListItemsSectionsType } from './types';
import ListItemsSection from './ListItemsSection';

interface Props {
  clientTitleBoxRef: React.RefObject<HTMLDivElement>;
  taxAssumptionsBoxRef: React.RefObject<HTMLDivElement>;
  safeHarborBoxRef: React.RefObject<HTMLDivElement>;
  numbersOutputRef: React.RefObject<HTMLDivElement>;
  taxNotesRef: React.RefObject<HTMLDivElement>;
  inputRefs: IInputRefs;
  toggleSideBarNavSummary: () => void;
  window?: () => Window;
}

const SideBarNavSummary = (props: Props) => {
  const { window } = props;
  const drawerWidth = '14%';

  const taxAssumptionsState = useAppSelector(taxAssumptionsSelector);
  const safeHarborComparisonFromStore = taxAssumptionsState.safeHarborComparison;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [inputDropDown, setInputDropDown] = React.useState(false);

  const handleDrawerToggle: OnClickFunction = () => {
    setMobileOpen(!mobileOpen);
  };

  const inputButtonClick: () => void = () => {
    setInputDropDown((prevInputDropDown) => !prevInputDropDown);
  };

  const scrollToComponent =
    (ref: React.RefObject<HTMLDivElement>): OnClickFunction =>
      () => {
        if (ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

  const variablesArray: ListItemsSectionsType = [
    ['Info', scrollToComponent(props.clientTitleBoxRef)],
    ['Assumptions', scrollToComponent(props.taxAssumptionsBoxRef)],
    ['Safe Harbor', scrollToComponent(props.safeHarborBoxRef)],
    ['Input', inputButtonClick],
  ];

  const inputArray: ListItemsSectionsType = [
    ['Income', scrollToComponent(props.inputRefs.inputIncomeRef)],
    ['Adjustments', scrollToComponent(props.inputRefs.inputAdjustmentsRef)],
    ['Itemized', scrollToComponent(props.inputRefs.inputItemizedRef)],
    ['Other', scrollToComponent(props.inputRefs.inputOtherRef)],
    ['Fed Pmts', scrollToComponent(props.inputRefs.inputFedPmtsRef)],
    ['State Pmts', scrollToComponent(props.inputRefs.inputStatePmtsRef)],
  ];

  const summaryArray: ListItemsSectionsType = [
    ['Summary', scrollToComponent(props.numbersOutputRef)],
    ['Notes', scrollToComponent(props.taxNotesRef)],
  ];

  const drawer = (
    <Box sx={{ color: 'white' }}>
      {/* <Toolbar /> */}
      <Box sx={{ textAlign: 'center', margin: '5% 4%' }}>
        <ThemeProvider theme={titleTheme}>
          <Typography variant="h1">
            TAX
            <br />
            PROJECTION
            <br />
            .COM
            <br />
          </Typography>
        </ThemeProvider>
      </Box>
      <Divider />
      <List>
        <ThemeProvider theme={listThemeOne}>
          <ListItemsSection array={variablesArray} safeHarborComparison={safeHarborComparisonFromStore} />
        </ThemeProvider>
        <ThemeProvider theme={listThemeTwo}>
          {inputDropDown ? <ListItemsSection array={inputArray} /> : null}
        </ThemeProvider>
        <ThemeProvider theme={listThemeOne}>
          <ListItemsSection array={summaryArray} />
        </ThemeProvider>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={props.toggleSideBarNavSummary}>
            <ListItemText primary={'Hide'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#1976d2',
              color: 'white',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              background: 'linear-gradient(to top, #57a1f8, #0047ab)',
              color: 'white',
              overflowX: 'hidden',
              overflowY: 'auto',
              scrollbarWidth: 'none', // for Firefox, hide scrollbar by default
              '&::-webkit-scrollbar': {
                width: '0px', // for Webkit browsers, hide scrollbar by default
              },
              '&:hover': {
                scrollbarWidth: 'auto', // for Firefox, show scrollbar on hover
                '&::-webkit-scrollbar': {
                  width: '12px', // for Webkit browsers, show scrollbar on hover
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  '&:hover': {
                    background: '#555',
                  },
                },
              },
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, padding: '0' }}>
        {/* <Toolbar /> */}
      </Box>
    </Box>
  );
};

export default SideBarNavSummary;
