import { addTotalNumbersForItemized } from './addTotalNumbersForItemized';
import { INumbersInputAsNumbers, IItemizedDeductionsCalcFederalAsNumbers } from '../types';
import {  
  itemizedDeductionsCalcFederalDefault,
  schAMedicalLimitationPercentage, 
  schATaxesPaidMFSLimit,
  schATaxesPaidOtherLimit
} from '../constants/scheduleAConstants';
import aGISchALimitationCalc from './aGISchALimitationCalc';
import charitySchALimitationCalc from './charitySchALimitationCalc';

const federalItemizedCalcFunction = (
  agi: number, 
  values: INumbersInputAsNumbers, 
  filingStatus: string) : [number, IItemizedDeductionsCalcFederalAsNumbers] => {

  const returnObj = { ...itemizedDeductionsCalcFederalDefault };

  // All input values for Sch A Limitation Functions should be positive numbers. They will be returned to a negative number on the output.
  const medicalExpensesLimited: number = aGISchALimitationCalc(Math.abs(values['medical']), agi, schAMedicalLimitationPercentage);
  const taxesExpensesLimited: number = taxesSchACalc(Math.abs(values['taxesPaidItemized']), filingStatus);
  const charityExpensesLimited: number = charitySchALimitationCalc(
    Math.abs(values['charitySixty']), 
    Math.abs(values['charityFifty']), 
    Math.abs(values['charityThirty']), 
    Math.abs(values['charityOther']), 
    agi
  );

  const total: number = addTotalNumbersForItemized(medicalExpensesLimited, taxesExpensesLimited, values['interestPaidItemized'], charityExpensesLimited, 
    values['otherFederalItemized']);

  returnObj.medical = medicalExpensesLimited;
  returnObj.taxesPaidItemized = taxesExpensesLimited;
  returnObj.interestPaidItemized = values['interestPaidItemized'];
  returnObj.charity = charityExpensesLimited;
  returnObj.otherFederalItemized = values['otherFederalItemized'];

  return [ total, returnObj ];
};

export default federalItemizedCalcFunction;


// SALT Expenses Limitation
const taxesSchACalc = (stateTaxes: number, filingStatus?: string): number => {
  const thresholdLimit: number = filingStatus === 'MFS' ? schATaxesPaidMFSLimit : schATaxesPaidOtherLimit;
  const totalStateTaxesLimited: number = stateTaxes > thresholdLimit ? thresholdLimit : stateTaxes;
  return -totalStateTaxesLimited;
};
