import { createTheme } from '@mui/material/styles';

export const themeWorksSans = createTheme({
  typography: {
    fontFamily: [
      'Work Sans',
      'sans-serif',
    ].join(','),
  },
});
