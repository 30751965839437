// I wanted to use more specific TS types but there were too many errors, I just could not get it to work. Possible refactor at some point.

// Input must be a single nested object
const addCommasToSetOfNumbers = (
  numbers: any
) => {
  const numbersDataToChange = Object.assign({}, numbers);
  const keys = Object.keys(numbersDataToChange) as Array<string>;
  keys.forEach(key => {
    const numberToAddCommasTo = numbersDataToChange[key];
    numbersDataToChange[key] = formatWithCommas(numberToAddCommasTo);
  });
  return numbersDataToChange;
};

const formatWithCommas = (number: number | string) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export default addCommasToSetOfNumbers;
