import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// temporary until I can find better backend hosting or pay for current backend hosting 

interface DbStatusXProps {
  url: string | undefined
}

const DbStatusX = ({ url }: DbStatusXProps) => {

  const [status, setStatus] = useState<string>('Disconnected');
  const reconnectIntervalId = useRef<null | NodeJS.Timeout>(null);
  const { getAccessTokenSilently } = useAuth0();
  const audience = process.env.REACT_APP_AUDIENCE;

  const getAccessToken = async (audience: string | undefined) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience
        },
      });
      return accessToken;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStatus = async () => {

    const accessToken = await getAccessToken(audience);

    try {
      const response = await fetch(`${url}/api/dbStatus`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      const dbStatus = data.status;
      if (dbStatus === 'Connected') {
        if (status !== 'Connected') {
          setStatus('Connected');
        }
        // Start the 14-minute reconnect interval only if it's not already running
        if (!reconnectIntervalId.current) {
          startReconnectInterval();
        }
      } else {
        setStatus('Disconnected');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const startReconnectInterval = () => {
    // Set interval to check connection every 14 minutes
    reconnectIntervalId.current = setInterval(fetchStatus, 840000);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      fetchStatus();
    }
  };

  useEffect(() => {
    let counter = 0;
    const interval = setInterval(() => {
      fetchStatus();
      counter += 15000;
      if (counter > 890000) {
        clearInterval(interval);
      }
    }, 15000);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(interval);
      if (reconnectIntervalId.current) {
        clearInterval(reconnectIntervalId.current);
        reconnectIntervalId.current = null;
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className="client-title-box-blurb" id={`client-title-box-blurb-${status === 'Connected' ? 'connected' : 'disconnected'}`}>
      {
        status === 'Connected'
          ? 'Database is connected!'
          : 'Note, due to budgetary restrictions it might take up to 2 minutes or more to connect to the database (account required).'
      }
      <div className="client-title-box-blurb-loading">
        {status === 'Connected' ? null : 'LOADING...'}
      </div>
    </div>
  );
};

export default DbStatusX;
