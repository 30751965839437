import { IUserDataClientInfos } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';
import './loginDropDown.scss';

interface UserProfileBoxProps {
    isOpen: boolean;
    userData: IUserDataClientInfos[];
    handleCancel: () => void;
}

const UserProfileBox = ({
  isOpen,
  userData,
  handleCancel
}: UserProfileBoxProps) => {

  const { user, isAuthenticated, isLoading } = useAuth0();

  if (!isOpen) {
    return null;
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }

  else if (user !== undefined && isAuthenticated) {
    return (
      <div className="user-profile-box-overlay">
        <div className="user-profile-box">
          <h4><b>PROFILE OF {user.nickname}</b></h4>
          <div className="user-details">
            <p>UserName: <span>{user.nickname}</span></p>
            <p>Email: <span>{user.email}</span></p>
            <p>User&#39;s Clients:</p>
            <ul>
              {userData.map((client) => (
                <li key={client.clientInfo._id}>
                  Client Name: <b>{client.clientInfo.clientName}</b>&nbsp;&nbsp;&nbsp;Client Id: <b>{client.clientInfo.clientId}</b>
                </li>
              ))}
            </ul>
          </div>
          <div className="user-profile-box-button-group">
            <button className="user-profile-box-button-confirmation" type="button" onClick={handleCancel}>OK</button>
          </div>
        </div>
      </div >
    );
  }
  else {
    return null;
  }
};

export default UserProfileBox;
