import StateColumnView from './StateColumnView';
import { 
  IItemizedDeductionsCalcFederal,
  IItemizedDeductionsCalcNewYork } from '../../types';
import {
  itemizedDeductionsElements,
  itemizedDeductionsNewYorkElements,
} from '../../constants/scheduleAConstants';
import './numbersOutput.scss';

interface Props {
  itemizedDeductionsCalcFederalValues: IItemizedDeductionsCalcFederal;
  itemizedDeductionsCalcStateValues: IItemizedDeductionsCalcNewYork;
  totalFederalItemized: number | string;
  totalStateItemized: number | string;
  NA: string;
  residencyFromStore: string;
  hideUnusedRows: boolean;
}

const ItemizedOutput = (props: Props) => {

  return (
    <tbody style={{ borderTop: 'none' }}>
     
      {itemizedDeductionsElements.filter((item) => 
        props.itemizedDeductionsCalcFederalValues[item.hardValue as keyof IItemizedDeductionsCalcFederal] !== '0' || !props.hideUnusedRows)
        .map((item) => {
          return (
            <tr className="table-row-item-regular" key={item.id}>
              <th scope="row" className="table-description-item">{item.element}</th>
              <td>{props.itemizedDeductionsCalcFederalValues[item.hardValue as keyof IItemizedDeductionsCalcFederal]}</td>
              {<StateColumnView
                residency={props.residencyFromStore}
                nAValue={props.NA}
                hardValue={item.hardNewYorkValue}
                outputValue={props.itemizedDeductionsCalcStateValues[item.hardNewYorkValue as keyof IItemizedDeductionsCalcNewYork]}
              />
              }
            </tr>
          );
        })}

      {/* New York Specific */}
      {itemizedDeductionsNewYorkElements.filter((item) => 
        props.itemizedDeductionsCalcStateValues[item.hardNewYorkValue as keyof IItemizedDeductionsCalcNewYork] !== '0' || !props.hideUnusedRows)
        .map((item) => {
          return (
            <tr className="table-row-item-regular" key={item.id}>
              <th scope="row" className="table-description-item">{item.element}</th>
              <td className="table-na-section">{props.NA}</td>
              {<StateColumnView
                residency={props.residencyFromStore}
                nAValue={props.NA}
                hardValue={item.hardNewYorkValue}
                outputValue={props.itemizedDeductionsCalcStateValues[item.hardNewYorkValue as keyof IItemizedDeductionsCalcNewYork]}
              />
              }
            </tr>
          );
        })}

      {/* Totals */}
      <tr className="table-total-row">
        <th scope="row">TOTAL ITEMIZED</th>
        <td>{props.totalFederalItemized}</td>
        {<StateColumnView
          residency={props.residencyFromStore}
          nAValue={props.NA}
          outputValue={props.totalStateItemized}
        />}
      </tr>
      <tr className="table-spacing-row">
        <td>&nbsp;</td>
      </tr>
    </tbody>
  );
};

export default ItemizedOutput;
