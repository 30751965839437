import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import CopyrightIcon from '@mui/icons-material/Copyright';
import './footer.scss';

const Footer = () => {

  const year = new Date().getFullYear();

  return (
    <div id="footerContactWelcome" className='footer-container-full-width-ls'>
      <div className="footer-contact-container-ls">
        <div className="footer-column">
          <h1>ABOUT</h1>
          Developed and built by <b>Russell Monteith</b> who is a Certified Public Accountant and Professional Software Engineer based out of New York City.
        </div>
        <div className="footer-column">
          <h1>CONTACT</h1>
          For bugs, feedback, questions, tax/tech consultations, or partnerships, send <b>Russell</b> an e-mail at <a href="mailto: russell@monteithtaxcpa.com"><b>russell@monteithtaxcpa.com</b></a>
        </div>
        <div className="footer-column">
          <h1>CONNECT</h1>
          <a href="https://www.linkedin.com/in/russell-monteith-cpa-0a43975a/" target="_blank" rel="noreferrer" className='footer-social-icons'>
            <LinkedInIcon />
          </a>
          <a href="https://github.com/russelltheprogrammer" target="_blank" rel="noreferrer" className='footer-social-icons'>
            <GitHubIcon />
          </a>
          <a href="mailto:russell@monteithtaxcpa.com" className='footer-social-icons'>
            <EmailIcon />
          </a>
        </div>
      </div>
      <div className="footer-info-container">
        <div className="footer-info">
          <p>
            <span style={{'verticalAlign': 'middle'}}><CopyrightIcon /></span>&nbsp;&nbsp;Copyright {year} - All Rights Reserved - RUSSELL MONTEITH CPA PLLC
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
