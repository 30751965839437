import StateColumnView from './StateColumnView';
import './numbersOutput.scss';

interface Props {
  description: string,
  federalValue: string,
  stateValue: string,
  residency: string,
  hideRow: boolean,
}

const NumbersOutputTaxPaymentsRow = (props: Props) => {

  if (props.federalValue !== '0' || props.stateValue !== '0' || !props.hideRow) {
    return (
      <tr className="table-row-item-regular">
        <th scope="row" className="table-description-item">{props.description}</th>
        <td>{props.federalValue}</td>
        {< StateColumnView
          residency={props.residency}
          outputValue={props.stateValue}
        />
        }
      </tr>
    );
  }
  else {
    return null;
  }

};

export default NumbersOutputTaxPaymentsRow;
