const safeHarborTaxCalculate = (safeHarborPercentage: string, safeHarborTax: string, quarterFactor: number, payments: number): number => {

  const safeHarborPercentageNumber = parseInt(safeHarborPercentage) / 100;
  const safeHarborTaxNumber = parseInt(safeHarborTax);
  const safeHarborQuarterTotalTax = (safeHarborTaxNumber * safeHarborPercentageNumber) * quarterFactor;
  const safeHarborQuarterTotalTaxLessPayments = Math.round(safeHarborQuarterTotalTax - payments);

  return safeHarborQuarterTotalTaxLessPayments;
};

export default safeHarborTaxCalculate;
