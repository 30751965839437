import {
  clientInfoDefault,
  numbersAnnualizationDefault,
  taxAssumptionsDefault,
  numbersInputDefault,
  safeHarborInputDefault
} from '../../TaxProjectionApp/constants/generalConstants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import {
  INumbersAnnualization,
  INumbersInput,
  IClientInfo,
  ITaxAssumptionsReduxModel,
  ITaxProjectionDataToSave,
  ISafeHarborInput
} from '../../TaxProjectionApp/types';

const initialState: ITaxProjectionDataToSave = {
  clientInfo: clientInfoDefault,
  taxAssumptions: taxAssumptionsDefault,
  numbersAnnualization: numbersAnnualizationDefault,
  numbersInput: numbersInputDefault,
  safeHarborInput: safeHarborInputDefault,
  taxNotes: ''
};

const taxProjectionDataSlice = createSlice({
  name: 'taxInput',
  initialState,
  reducers: {
    clientInfoAction: (
      state,
      action: PayloadAction<IClientInfo>
    ) => {
      state.clientInfo = action.payload;
    },
    numbersAnnualizationAction: (
      state,
      action: PayloadAction<INumbersAnnualization>
    ) => {
      state.numbersAnnualization = action.payload;
    },
    numbersInputAction: (
      state,
      action: PayloadAction<INumbersInput>
    ) => {
      state.numbersInput = action.payload;
    },
    taxAssumptionsAction: (
      state,
      action: PayloadAction<ITaxAssumptionsReduxModel>
    ) => {
      state.taxAssumptions = action.payload;
    },
    safeHarborInputAction: (
      state,
      action: PayloadAction<ISafeHarborInput>
    ) => {
      state.safeHarborInput = action.payload;
    },
    taxNotesAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.taxNotes = action.payload;
    },
  },
});

export const {
  clientInfoAction,
  numbersAnnualizationAction,
  numbersInputAction,
  taxAssumptionsAction,
  safeHarborInputAction,
  taxNotesAction,
} = taxProjectionDataSlice.actions;

// Selectors
export const taxProjectionDataSelector = (state: RootState) => state.taxProjectionDataState;
export const clientInfoSelector = (state: RootState) => state.taxProjectionDataState.clientInfo;
export const numbersAnnualizationSelector = (state: RootState) => state.taxProjectionDataState.numbersAnnualization;
export const numbersInputSelector = (state: RootState) => state.taxProjectionDataState.numbersInput;
export const taxAssumptionsSelector = (state: RootState) => state.taxProjectionDataState.taxAssumptions;
export const safeHarborInputSelector = (state: RootState) => state.taxProjectionDataState.safeHarborInput;
export const taxNotesSelector = (state: RootState) => state.taxProjectionDataState.taxNotes;

export default taxProjectionDataSlice.reducer;
