import { Box } from '@mui/material';
import { ListItemsSectionsType } from './types';
import ListItemComponent from './ListItemComponent';

interface ListItemsSectionProps {
    array: ListItemsSectionsType;
    safeHarborComparison?: string;
  }

const ListItemsSection = (props: ListItemsSectionProps) => {

  return (  
    <Box>
      {props.array.map((value, index) => (
        props.safeHarborComparison === 'No' && value[0] === 'Safe Harbor'
          ? null 
          : <ListItemComponent key={index} text={value[0]} onClick={value[1]} />
      ))}
    </Box>);
};
 
export default ListItemsSection;
