import { INumbersInput, ITaxProjectionDataToSave } from '../types';

const formatTaxProjectionData = (data: ITaxProjectionDataToSave) => {

  const formattedData: ITaxProjectionDataToSave = JSON.parse(JSON.stringify(data));

  removeEmptyStrings(formattedData.numbersInput);

  return formattedData;
};
  
export default formatTaxProjectionData;

const removeEmptyStrings = (object: INumbersInput) => {
  Object.keys(object).forEach((key) => {
    if (object[key as keyof INumbersInput] === '') {
      object[key as keyof INumbersInput] = '0';
    }
  });
};
