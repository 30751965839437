import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import './landingPage.scss';

const LandingPage = () => {
  return ( 
    <div className='landing-page'>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};
 
export default LandingPage;
