import { addTotalNumbersForItemized } from './addTotalNumbersForItemized';
import { 
  INumbersInputAsNumbers, 
  IItemizedDeductionsCalcNewYorkAsNumbers } from '../types';
import { 
  itemizedDeductionsCalcNewYorkDefault,
  newYorkSchAMedicalLimitationPercentage,
  newYorkSchAPortfolioLimitationPercentage,
  newYorkSchALineFortyThresholdMFJ,
  newYorkSchALineFortyThresholdHOH,
  newYorkSchALineFortyThresholdSingle,
  newYorkSchALineFortyThresholdMFS,
  newYorkSchALineFortySixWorksheetThreeFloor,
  newYorkSchALineFortySixWorksheetThreeCeiling,
  newYorkSchALineFortySixWorksheetFourFloor,
  newYorkSchALineFortySixWorksheetFourCeiling,
  newYorkSchALineFortySixBETWEENWorksheetFourFiveFloor,
  newYorkSchALineFortySixBETWEENWorksheetFourFiveCeiling,
  newYorkSchALineFortySixWorksheetFiveFloor,
  newYorkSchALineFortySixWorksheetFiveCeiling,
  newYorkSchALineFortySixWorksheetSixFloor
} from '../constants/scheduleAConstants';
import aGISchALimitationCalc from './aGISchALimitationCalc';
import charitySchALimitationCalc from './charitySchALimitationCalc';

const newYorkItemizedCalcFunction = (
  agi: number, 
  values: INumbersInputAsNumbers, 
  filingStatus: string): [number, IItemizedDeductionsCalcNewYorkAsNumbers] => {

  const returnObj = { ...itemizedDeductionsCalcNewYorkDefault };
  
  const newYorkSchALineFortyThresholdFilingStatusMap: Record<string, number> = {
    'MFJ': newYorkSchALineFortyThresholdMFJ,
    'HOH': newYorkSchALineFortyThresholdHOH,
    'Single': newYorkSchALineFortyThresholdSingle,
    'MFS': newYorkSchALineFortyThresholdMFS
  };
  const newYorkSchALineFortyThreshold: number = newYorkSchALineFortyThresholdFilingStatusMap[filingStatus];

  // All input values for Sch A Limitation Functions should be positive numbers. They will be returned to a negative number on the output.
  const medicalExpensesLimited: number = aGISchALimitationCalc(Math.abs(values['medical']), agi, newYorkSchAMedicalLimitationPercentage);
  const charityExpensesLimited: number = charitySchALimitationCalc(
    Math.abs(values['charitySixty']),
    Math.abs(values['charityFifty']),
    Math.abs(values['charityThirty']),
    Math.abs(values['charityOther']),
    agi
  );
  const portfolioDeductionsLimited: number = aGISchALimitationCalc(Math.abs(values['statePortfolioItemized']), agi, newYorkSchAPortfolioLimitationPercentage);

  let total: number = addTotalNumbersForItemized(medicalExpensesLimited, values['taxesPaidItemized'], values['interestPaidItemized'], charityExpensesLimited,
    portfolioDeductionsLimited, values['stateSpecificItemized']);

  returnObj.newYorkMedical = medicalExpensesLimited;
  returnObj.newYorkTaxesPaidItemized = values['taxesPaidItemized'];
  returnObj.newYorkInterestPaidItemized = values['interestPaidItemized'];
  returnObj.newYorkCharity = charityExpensesLimited;
  returnObj.newYorkPortfolioDeductions = portfolioDeductionsLimited;
  returnObj.newYorkStateSpecificItemized = values['stateSpecificItemized'];
  returnObj.newYorkLimitationTotal = 0;

  // Line 40, Total itemized deductions worksheet, includes calculation of line 41 too
  const lineFortyWorksheetLineThree = total; // total before limitation calculations
  let lineFortyWorksheetLineNine = 0;
  if (agi > newYorkSchALineFortyThreshold) {
    if (newYorkSchALineFortyThreshold < agi) {
      const lineFortyWorksheetLineFour = -lineFortyWorksheetLineThree * .8; // positive
      const lineFortyWorksheetLineSeven = agi - newYorkSchALineFortyThreshold; // positive
      const lineFortyWorksheetLineEight = lineFortyWorksheetLineSeven * .3; // positive
      lineFortyWorksheetLineNine = lineFortyWorksheetLineFour > lineFortyWorksheetLineEight ? lineFortyWorksheetLineEight : lineFortyWorksheetLineFour; // positive
      total += lineFortyWorksheetLineNine; // negative
    }
  }

  // Line 41 Limitation - Worksheet 2
  // If under Threshold
  if (agi < newYorkSchALineFortyThreshold) {
    total -= values['taxesPaidItemized'];
  }
  // If above Threshold
  else if (agi > newYorkSchALineFortyThreshold) {
    const lineFortyOneWorksheetTwoLineThree = Math.abs(lineFortyWorksheetLineNine / lineFortyWorksheetLineThree); // positive
    const lineFortyOneWorksheetTwoLineSeven = values['taxesPaidItemized'] * lineFortyOneWorksheetTwoLineThree; // negative
    const lineFortyOneWorksheetTwoLineEight = values['taxesPaidItemized'] - lineFortyOneWorksheetTwoLineSeven; // negative
    total -= lineFortyOneWorksheetTwoLineEight; // negative
  }
  else {
    total -= values['taxesPaidItemized'];
  }

  // Worksheet 3 of NY Form IT-196
  if (agi > newYorkSchALineFortySixWorksheetThreeFloor && agi < newYorkSchALineFortySixWorksheetThreeCeiling) {
    if (newYorkSchALineFortyThreshold < agi) {

      const worksheetThreeLineTwoFilingStatusMap: Record<string, number> = {
        'MFJ': 200000,
        'HOH': 150000,
        'Single': 100000,
        'MFS': 100000
      };
      const worksheetThreeLineTwo: number = worksheetThreeLineTwoFilingStatusMap[filingStatus];

      const worksheetThreeLineThree = agi - worksheetThreeLineTwo; // positive number
      const worksheetThreeLineFour = worksheetThreeLineThree > 50000 ? 50000 : worksheetThreeLineThree; // positive number
      const worksheetThreeLineFive = worksheetThreeLineFour / 50000; // positive number
      const worksheetThreeLineSix = total * .25; // negative number
      const worksheetThreeLineSeven = worksheetThreeLineFive * worksheetThreeLineSix; // negative number
      total -= worksheetThreeLineSeven; // negative number
    }
  }

  // Worksheet 4 of NY Form IT-196
  if (agi > newYorkSchALineFortySixWorksheetFourFloor && agi < newYorkSchALineFortySixWorksheetFourCeiling) {
    const worksheetFourLineOne = (agi - newYorkSchALineFortySixWorksheetFourFloor) > 50000 ? 50000 : (agi - newYorkSchALineFortySixWorksheetFourFloor); // positive number
    const worksheetFourLineTwo = worksheetFourLineOne / 50000; // positive number
    const worksheetFourLineThree = total * .25; // negative number
    const worksheetFourLineFour = worksheetFourLineTwo * worksheetFourLineThree; // negative number
    const worksheetFourLineFive = worksheetFourLineThree + worksheetFourLineFour; // negative number
    total -= worksheetFourLineFive; // negative number
  }

  // Limitation that has no Worksheet, it is between Worksheet 4 and Worksheet 5
  if (agi > newYorkSchALineFortySixBETWEENWorksheetFourFiveFloor && agi < newYorkSchALineFortySixBETWEENWorksheetFourFiveCeiling) {
    const adjustment = total * .5; // negative number
    total -= adjustment; // negative number
  }

  // Worksheet 5 of NY Form IT-196
  if (agi > newYorkSchALineFortySixWorksheetFiveFloor && agi < newYorkSchALineFortySixWorksheetFiveCeiling) {
    const worksheetFiveLineTwo = charityExpensesLimited * .5; // negative number
    const worksheetFiveLineThree = total - worksheetFiveLineTwo; // negative number
    total -= worksheetFiveLineThree; // negative number
  }

  // Worksheet 6 of NY Form IT-196
  if (agi > newYorkSchALineFortySixWorksheetSixFloor) {
    const worksheetSixLineTwo = charityExpensesLimited * .25; // negative number
    const worksheetSixLineThree = total - worksheetSixLineTwo; // negative number
    total -= worksheetSixLineThree; // negative number
  }

  returnObj.newYorkLimitationTotal = isNaN(Math.round(total - lineFortyWorksheetLineThree)) ? 0 : Math.round(total - lineFortyWorksheetLineThree);

  return [ Math.round(total), returnObj ];
};

export default newYorkItemizedCalcFunction;
