const NotFoundPage = () => {

  return (
    <div id="not-found-page" style={{ textAlign: 'center', marginTop: '50px', color: 'black' }}>
      <h1>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <p>It might have been moved, removed, or its name might have changed.</p>
    </div>
  );
};

export default NotFoundPage;
