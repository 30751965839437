import { INumbersAnnualization, INumbersInputAsNumbers } from '../types';

const annualizeDataFunction = (
  numbersData: INumbersInputAsNumbers,
  annualizationData: INumbersAnnualization,
  annualizationFactor: number): INumbersInputAsNumbers => {

  const numbersDataToChange = Object.assign({}, numbersData);
  const annualizationDataChange = Object.assign({}, annualizationData);

  // If annualization checkbox is checked then number should not be annualized. 
  // This part of algorithm annualizes all input numbers except those that have the box checked

  Object.keys(numbersDataToChange).forEach(key => {
    Object.keys(annualizationDataChange).forEach(key2 => {
      if (annualizationDataChange[key2 as keyof INumbersAnnualization] === 'on' && key === key2) {
        numbersDataToChange[key as keyof INumbersInputAsNumbers] =
                    Math.round((numbersDataToChange[key as keyof INumbersInputAsNumbers] * annualizationFactor));
      }
    });
  });
  return numbersDataToChange;
};

export default annualizeDataFunction;
