
// Charity Expenses Limitation
const charitySchALimitationCalc = (charitySixty: number, charityFifty: number, charityThirty: number, charityOther: number, agi: number) => {
  const sixtyPercentAgi = 0.60 * agi;
  const fiftyPercentAgi = 0.50 * agi;
  const thirtyPercentAgi = 0.30 * agi;
  let totalCharityDeduction = 0;
   
  if (charitySixty > sixtyPercentAgi) {
    totalCharityDeduction += sixtyPercentAgi;
  }
  else {
    totalCharityDeduction += charitySixty;
  }
  
  if (charityFifty > 0) {
    const limitation = fiftyPercentAgi - totalCharityDeduction > 0 ? fiftyPercentAgi - totalCharityDeduction : 0;
    if (limitation > charityFifty) {
      totalCharityDeduction += charityFifty;
    } 
    else {
      totalCharityDeduction += limitation;
    }
  }
  
  if (charityThirty > 0) {
    const limitation = thirtyPercentAgi - totalCharityDeduction > 0 ? thirtyPercentAgi - totalCharityDeduction : 0;
    if (limitation > charityThirty) {
      totalCharityDeduction += charityThirty;
    } 
    else {
      totalCharityDeduction += limitation;
    }
  }
  
  totalCharityDeduction += charityOther;
  
  return -totalCharityDeduction;
};

export default charitySchALimitationCalc;
