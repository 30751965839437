import './sideBarToggles.scss';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

interface Props {
  toggleSideBarNavSummary: () => void;
}

const SideBarToggle = (props: Props) => {

  return ( 
    <div className="side-bar-toggle-container">
      <button className="side-bar-toggle-button" onClick={props.toggleSideBarNavSummary}>
        <MenuOpenIcon />
      </button>
    </div> 
  );
};
 
export default SideBarToggle;
