import { 
  taxIncomeElements, 
  taxAdjustmentElements, 
  taxOtherState, 
  taxOtherFederal,
  paymentsState,
  paymentsFederal 
} from '../constants/generalConstants';
import {
  itemizedDeductionsElements,
  itemizedDeductionsNewYorkElements
} from '../constants/scheduleAConstants';
import { 
  INumbersInputAsNumbers, 
  ITaxIncomeDeductionsPaymentsOutput,
  IClientInfo,
  ITaxAssumptionsReduxModel,
  IItemizedDeductionsCalcFederalAsNumbers,
  IItemizedDeductionsCalcNewYorkAsNumbers,
  IItemizedDeductionsElements
} from '../types';

interface exportDataToCsvProps {
  clientInfoFromStore: IClientInfo;
  taxAssumptionsFromStore: ITaxAssumptionsReduxModel;
  taxInputDataAnnualized: INumbersInputAsNumbers;
  totalIncome: number;
  totalAdjustmentsAfterSETaxDed: number;
  deductibleSETax: number;
  totalStateOther: number;
  capitalLossLimitation: number;
  federalAdjustedGrossIncome: number;
  stateAdjustedGrossIncome: number;
  federalStandard: number | undefined;
  stateStandard: number | undefined;
  itemizedDeductionsCalcFederalValues: IItemizedDeductionsCalcFederalAsNumbers;
  itemizedDeductionsCalcStateValues: IItemizedDeductionsCalcNewYorkAsNumbers;
  totalFederalItemized: number;
  totalStateItemized: number;
  totalFederalOther: number;
  stateDependentExemptions: number;
  federalTaxableIncome: number;
  stateTaxableIncome: number;
  federalTaxCalculated: number;
  stateTaxCalculated: number;
  federalOtherTaxes: number;
  stateOtherTaxes: number;
  federalChildTaxCredits: number;
  totalFederalTax: number;
  totalStateTax: number;
  totalFederalQuarterTax: number;
  totalStateQuarterTax: number;
  totalFederalPayments: number;
  totalStatePayments: number;
  totalFedTaxDueOverpaid: number;
  totalStateTaxDueOverpaid: number;
  federalSafeHarbor: number;
  stateSafeHarbor: number;
  federalMinimumNumber: number;
  stateMinimumNumber: number;
}

const exportDataToCsv = ({
  clientInfoFromStore,
  taxAssumptionsFromStore,
  taxInputDataAnnualized,
  totalIncome,
  totalAdjustmentsAfterSETaxDed,
  deductibleSETax,
  totalStateOther,
  capitalLossLimitation,
  federalAdjustedGrossIncome,
  stateAdjustedGrossIncome,
  federalStandard,
  stateStandard,
  itemizedDeductionsCalcFederalValues,
  itemizedDeductionsCalcStateValues,
  totalFederalItemized,
  totalStateItemized,
  totalFederalOther,
  stateDependentExemptions,
  federalTaxableIncome,
  stateTaxableIncome,
  federalTaxCalculated,
  stateTaxCalculated,
  federalOtherTaxes,
  stateOtherTaxes,
  federalChildTaxCredits,
  totalFederalTax,
  totalStateTax,
  totalFederalQuarterTax,
  totalStateQuarterTax,
  totalFederalPayments,
  totalStatePayments,
  totalFedTaxDueOverpaid,
  totalStateTaxDueOverpaid,
  federalSafeHarbor,
  stateSafeHarbor,
  federalMinimumNumber,
  stateMinimumNumber

}: exportDataToCsvProps) => {
  const clientName = clientInfoFromStore.clientName ? clientInfoFromStore.clientName : 'Client Name';
  const clientId = clientInfoFromStore.clientName ? clientInfoFromStore.clientId : 'Client Id';
  const stateResidency = taxAssumptionsFromStore.residency;
  const stateResidencyColumn = stateResidency === 'None' ? '' : stateResidency;
  const quarter = taxAssumptionsFromStore.quarter;
  const filingStatus = taxAssumptionsFromStore.filingStatus;
  const standardOrItemized = taxAssumptionsFromStore.standard;
  const dependents = taxAssumptionsFromStore.dependents;
  const safeHarborComparison = taxAssumptionsFromStore.safeHarborComparison;
  const federalStandardValue: number = !federalStandard ? 0 : federalStandard;
  const stateStandardValue: number = !stateStandard ? 0 : stateStandard;
  const dataLineBreak = [''];

  const taxIncomeValuesArray: any[] = formatTaxValuesArray(taxIncomeElements, taxInputDataAnnualized, stateResidency);
  const taxAdjustmentsValuesArray: any[] = formatTaxValuesArray(taxAdjustmentElements, taxInputDataAnnualized, stateResidency);
  const taxOtherStateValuesArray: any[] = formatTaxValuesArray(taxOtherState, taxInputDataAnnualized, stateResidency, 'stateOnly');
  const itemizedValuesArray: any[] = formatItemizedDeductionsValuesArray(
    itemizedDeductionsElements, 
    itemizedDeductionsCalcFederalValues, 
    itemizedDeductionsCalcStateValues, 
    stateResidency);
  const itemizedValuesArrayStateOnly: any[] = formatItemizedDeductionsValuesArray(
    itemizedDeductionsNewYorkElements, 
    itemizedDeductionsCalcFederalValues, 
    itemizedDeductionsCalcStateValues, 
    stateResidency,
    'stateOnly');
  const itemizedTotalValuesArray: any[] = [['Total Itemized', totalFederalItemized, stateCheckValue(stateResidency, totalStateItemized)]];
  const taxOtherFederalValuesArray: any[] = formatTaxValuesArray(taxOtherFederal, taxInputDataAnnualized, 'None');

  // taxPaymentsValuesArray has a special format because values are all single values in taxInputAnnualized Obj. However, 2D output is needed for CSV format to match
  // the format in the Comp NumbersOutputWithTax
  const taxPaymentsValuesArray: any[] = formatTaxPaymentsValuesArray(taxInputDataAnnualized, stateResidency);
  
  // CSV Finished Template for Conversion
  const data: any[] = [
    ['TaxProjection.com'],
    ['Income, Deduction, and Tax Summary'],
    [clientName],
    [clientId],
    dataLineBreak,
    ['Quarter:', quarter],
    ['Filing Status:', filingStatus],
    ['Standard/Itemized:', standardOrItemized],
    ['Dependents:', dependents],
    ['State Residency:', stateResidency],
    ['Safe Harbor Comparison:', safeHarborComparison],
    dataLineBreak,
    dataLineBreak,
    ['Description', 'Federal', stateResidencyColumn],
    dataLineBreak,
    ...taxIncomeValuesArray,
    ['Total Income', totalIncome, stateCheckValue(stateResidency, totalIncome)],
    dataLineBreak,
    ...taxAdjustmentsValuesArray,
    ['Deductible S/E Tax', deductibleSETax, stateCheckValue(stateResidency, deductibleSETax)],
    ['Total Adjustments', totalAdjustmentsAfterSETaxDed, stateCheckValue(stateResidency, totalAdjustmentsAfterSETaxDed)],
    dataLineBreak,
    ...taxOtherStateValuesArray,
    ['Total State Other', '', stateCheckValue(stateResidency,totalStateOther)],
    dataLineBreak,
    ['Capital Loss Limitation', capitalLossLimitation, stateCheckValue(stateResidency, capitalLossLimitation)],
    dataLineBreak,
    ['Adjusted Gross Income', federalAdjustedGrossIncome, stateCheckValue(stateResidency, stateAdjustedGrossIncome)],
    dataLineBreak,
    ['Standard Deduction', federalStandardValue, stateCheckValue(stateResidency, stateStandardValue)],
    ...itemizedValuesArray,
    ...itemizedValuesArrayStateOnly,
    ...itemizedTotalValuesArray,
    dataLineBreak,
    ...taxOtherFederalValuesArray,
    ['Total Federal Other', totalFederalOther, ''],
    dataLineBreak,
    ['State Dependent Exemptions', '', stateCheckValue(stateResidency, stateDependentExemptions)],
    dataLineBreak,
    ['Taxable Income', federalTaxableIncome, stateCheckValue(stateResidency, stateTaxableIncome)],
    dataLineBreak,
    ['Total Calculated Tax', federalTaxCalculated, stateCheckValue(stateResidency, stateTaxCalculated)],
    dataLineBreak,
    ['Total Other Tax', federalOtherTaxes, stateCheckValue(stateResidency, stateOtherTaxes)],
    ['Federal Child Tax Credits', federalChildTaxCredits, ''],
    ['Total All Tax', totalFederalTax, stateCheckValue(stateResidency, totalStateTax)],
    dataLineBreak,
    ['Total Quarterly Tax Due', totalFederalQuarterTax, stateCheckValue(stateResidency, totalStateQuarterTax)],
    dataLineBreak,
    ...taxPaymentsValuesArray,
    ['Total Payments', totalFederalPayments, stateCheckValue(stateResidency, totalStatePayments)],
    dataLineBreak,
    ['Total Tax Due /(Overpaid)', totalFedTaxDueOverpaid, stateCheckValue(stateResidency, totalStateTaxDueOverpaid)],
    dataLineBreak,
    ['Safe Harbor Tax Due /(Overpaid)', federalSafeHarbor, stateCheckValue(stateResidency, stateSafeHarbor)],
    dataLineBreak,
    ['Minimum Tax Due /(Overpaid)', federalMinimumNumber, stateCheckValue(stateResidency, stateMinimumNumber)],
  ];

  // Create CSV Format
  const csvContent = data.map((e) => e.map((a: string | number) => `"${a.toString().replace(/"/g, '""')}"`).join(',')).join('\n');

  // Create blob link to download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'download.csv';
  link.href = url;
  link.click();
};

export default exportDataToCsv;

// combine both formatTaxValuesArray & formatItemizedDeductionsArray? Consider in future. They are very similar.

const formatTaxValuesArray = (
  taxConstants: ITaxIncomeDeductionsPaymentsOutput[],
  taxInputDataAnnualized: INumbersInputAsNumbers,
  stateResidency: string,
  stateOnly?: string,
) => {
  const outputArray: any = [];
  taxConstants.forEach((item) => {
    const numberValue: number = taxInputDataAnnualized[item.hardValue as keyof INumbersInputAsNumbers];
    const stateNumberValue: number | string = stateCheckValue(stateResidency, numberValue);
    if (stateOnly === 'stateOnly') {
      outputArray.push([item.element, '', stateNumberValue]);
    } else {
      outputArray.push([item.element, numberValue, stateNumberValue]);
    }
  });
  return outputArray;
};

const formatItemizedDeductionsValuesArray = (
  taxConstants: IItemizedDeductionsElements[],
  federalItemizedValues: IItemizedDeductionsCalcFederalAsNumbers,
  stateItemizedValues: IItemizedDeductionsCalcNewYorkAsNumbers,
  stateResidency: string,
  stateOnly?: string,
) => {
  const outputArray: any = [];
  taxConstants.forEach((item) => {
    const numberValue: number | string = 
    item.hardValue !== 'none' ?
      federalItemizedValues[item.hardValue as keyof IItemizedDeductionsCalcFederalAsNumbers] :
      '';
    const stateNumberValue: number | string = 
    item.hardNewYorkValue !== 'none' ?
      stateCheckValue(stateResidency, stateItemizedValues[item.hardNewYorkValue as keyof IItemizedDeductionsCalcNewYorkAsNumbers]) :
      '';
    if (stateOnly === 'stateOnly') {
      outputArray.push([item.element, '', stateNumberValue]);
    } else {
      outputArray.push([item.element, numberValue, stateNumberValue]);
    }
  });
  return outputArray;
};

const stateCheckValue = (residency: string, number: number): number | string => {
  if (residency === 'None') {
    return '';
  } else {
    return number;
  }
};

const formatTaxPaymentsValuesArray = (taxInputDataAnnualized: INumbersInputAsNumbers, stateResidency: string) => {
  const outputArray: any[] = [];

  paymentsFederal.forEach((item, index) => {
    outputArray.push(
      [
        item.element, 
        taxInputDataAnnualized[item.hardValue as keyof INumbersInputAsNumbers], 
        stateCheckValue(stateResidency, taxInputDataAnnualized[paymentsState[index].hardValue as keyof INumbersInputAsNumbers])
      ]
    );
  });
  return outputArray;
};
