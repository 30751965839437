import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CodeIcon from '@mui/icons-material/Code'; 
import BookIcon from '@mui/icons-material/Book';
import LoopIcon from '@mui/icons-material/Loop';
import FeatureCardItem from './FeatureCardItem';
import { themeWorksSans } from './themeWorksSans';

interface IFeatures {
  id: number;
  Icon: React.ElementType;
  title: string;
  description: string;
}

const FeatureCards = () => {

  const features: IFeatures[] = [
    {
      id: 0,
      Icon: BookIcon,
      title: 'Full Documentation',
      description: 'An entire documentation created by a Certified Public Accountant with line by line instructions on how to use the software, including basic tax information about how quarterly tax estimates work.'
    },
    {
      id: 1,
      Icon: CodeIcon,
      title: 'Expert Product Development',
      description: 'Developed by a Certified Public Accountant with years of experience in the tax industry. All of the programming was done by a CPA. It is extremely rare for a product like this to exist.'
    },
    {
      id: 2,
      Icon: LoopIcon,
      title: 'Continuous Improvement',
      description: 'The software is continuously being improved for a better overall experience based on feedback from users, other software engineers, and other accountants.'
    },
  ];

  return (
    <Box sx={{ 
      padding: {
        xs: '12% 5%',
        sm: '8% 5%',
        md: '6% 5%',
        lg: '3% 5%',
      }
    }}>
      <ThemeProvider theme={themeWorksSans}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography 
              variant="h4" 
              component="h2" 
              gutterBottom 
              align="center"
              sx={{ 
                textAlign: 'center', 
                fontWeight: '600', 
                fontSize: {
                  xs: '32px',  // smaller font size on extra small screens
                  sm: '35px',  // slightly larger font size on small screens
                  md: '38px',
                },
                mb: {
                  xs: 4,
                }
              }}
            >
              Features
            </Typography>
          </Grid>
          <Grid container spacing={4}>
            {features.map((feature) => (
              <FeatureCardItem key={feature.id} {...feature} />
            ))}
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

export default FeatureCards;
