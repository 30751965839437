export function addTotalNumbersForItemized(...values: number[]): number {
  const array: number[] = [];
  let sum = 0;

  for (let i = 0; i < values.length; i++) {
    array.push(values[i]);
  }

  sum = array.reduce((prevValue, currentValue) => {
    return prevValue + currentValue;
  });

  return sum;
}
