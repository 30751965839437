import { useAuth0 } from '@auth0/auth0-react';
import './loginDropDown.scss';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button className='login-drop-down-item' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>LOGOUT</button>
  );
};

export default LogoutButton;
