const capitalLossLimitationFunction = (shortTermCapitalGainLoss: number, longTermCapitalGainLoss: number): number => {
  let total = 0;
  const st: number = shortTermCapitalGainLoss;
  const lt: number = longTermCapitalGainLoss;
  let limitation = 0;
  total += st + lt;

  if (total > -3001) {
    return 0;
  }
  else {
    limitation += -total - 3000;
    return limitation;
  }
};

export default capitalLossLimitationFunction;
