import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { taxAssumptionsSelector, safeHarborInputAction, safeHarborInputSelector } from '../../redux/reducers/taxProjectionDataSlice';
import cleanUpNumberStrings from '../functions/cleanUpNumberStrings';
import { safeHarborSelectElements, safeHarborInputElements } from '../constants/generalConstants';
import { ISafeHarborInput } from '../types';
import './safeHarbor.scss';

interface Props {
  sideBarNavSummaryShow: boolean;
}
const SafeHarborInput = (props: Props) => {

  const taxAssumptionsState = useAppSelector(taxAssumptionsSelector);
  const safeHarborComparisonFromStore = taxAssumptionsState.safeHarborComparison;
  const safeHarborInputFromStore = useAppSelector(safeHarborInputSelector);
  const dispatch = useAppDispatch();

  const handleInput = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    const oldNumbersInputState = safeHarborInputFromStore;
    const newNumbersInputState = {
      ...oldNumbersInputState,
      [name]: value
    };
    newNumbersInputState.federalPriorYearTax = cleanUpNumberStrings(newNumbersInputState.federalPriorYearTax);
    newNumbersInputState.statePriorYearTax = cleanUpNumberStrings(newNumbersInputState.statePriorYearTax);
    dispatch(safeHarborInputAction(newNumbersInputState));
  };

  const handleSelect = (elementName: string, e: { target: HTMLSelectElement }) => {
    const tempValue = e.target.value;
    const oldNumbersInputState = safeHarborInputFromStore;
    const newNumbersInputState = {
      ...oldNumbersInputState,
      [elementName]: tempValue
    };
    dispatch(safeHarborInputAction(newNumbersInputState));
  };

  if (safeHarborComparisonFromStore === 'Yes') {
    return (
      <div className={props.sideBarNavSummaryShow ? 'safe-harbor-input-container' : 'safe-harbor-input-container-full-width'}>
        <h1 className="box-title">SAFE HARBOR CALCULATION (PRIOR YEAR TAX)</h1>
        <div className='safe-harbor-input-grid-container'>
          <div className="safe-harbor-input-grid">
            <div className="safe-harbor-input-grid-item-blank">
            &nbsp;
            </div>
            <div className="safe-harbor-input-grid-item">
            FEDERAL
            </div>
            <div className="safe-harbor-input-grid-item">
            STATE
            </div>
            <div className="safe-harbor-input-grid-item">
            PRIOR YEAR TAX
            </div>
            {safeHarborInputElements.map((item) =>
              <div key={item.key}>
                <input
                  type='text'
                  name={item.stateName}
                  className='safe-harbor-input-field'
                  value={safeHarborInputFromStore[item.stateName as keyof ISafeHarborInput]}
                  onChange={handleInput} />
              </div>
            )}
            <div className="safe-harbor-input-grid-item">
            S/H PERCENTAGE
            </div>
            {safeHarborSelectElements.map((item) =>
              <div key={item.key}>
                <select
                  name={item.stateName}
                  className='safe-harbor-select-field'
                  value={safeHarborInputFromStore[item.stateName as keyof ISafeHarborInput]}
                  onChange={(e) => handleSelect(item.stateName, e)} >
                  {item.array ?
                    item.array.map((value) =>
                      <option key={value.key} value={value.arrayValue}>{value.arrayValue}</option>
                    ) :
                    null}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
};

export default SafeHarborInput;
