import { useEffect } from 'react';
import TaxProjectionApp from './TaxProjectionApp/TaxProjectionApp';
import LandingPage from './LandingPage/LandingPage';
import NotFoundPage from './NotFoundPage';
import ErrorPage from './ErrorPage';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

const App = () => {
  const TitleHandler = () => {
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === '/taxapp') {
        document.title = 'TaxProjection.com | App';
      }
      else {
        document.title = 'TaxProjection.com | Estimated Taxes';
      }
    }, [location]);
    return null;
  };

  return (
    <div className="app">
      <Router>
        <TitleHandler />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/taxapp" element={<TaxProjectionApp />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route errorElement={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
