import {
  ITaxAssumptions,
  INumbersInput,
  INumbersAnnualization,
  ITaxIncomeDeductionsPaymentsOutput,
  ISchDModel,
  ITaxBracketModel,
  IStandardDeductionModel,
  IClientInfo,
  ITaxAssumptionsReduxModel,
  IUserDataClientInfos,
  ISafeHarborInput,
  ISafeHarborPercentages,
  ISafeHarborElements,
} from '../types';

const taxAssumptionsElements: ITaxAssumptions[] = [
  {
    id: 0,
    element: 'Quarter',
    stateName: 'quarter',
    array: [
      { id: 0, arrayValue: '1' },
      { id: 1, arrayValue: '2' },
      { id: 2, arrayValue: '3' },
      { id: 3, arrayValue: '4' },
    ],
  },
  {
    id: 1,
    element: 'Filing Status',
    stateName: 'filingStatus',
    array: [
      { id: 0, arrayValue: 'Single' },
      { id: 1, arrayValue: 'MFJ' },
      { id: 2, arrayValue: 'MFS' },
      { id: 3, arrayValue: 'HOH' },
    ],
  },
  {
    id: 2,
    element: 'Standard/Itemized',
    stateName: 'standard',
    array: [
      { id: 0, arrayValue: 'Optimize' },
      { id: 1, arrayValue: 'Standard' },
      { id: 2, arrayValue: 'Itemized' },
    ],
  },
  {
    id: 3,
    element: 'Dependents',
    stateName: 'dependents',
    array: [
      { id: 0, arrayValue: '0' },
      { id: 1, arrayValue: '1' },
      { id: 2, arrayValue: '2' },
      { id: 3, arrayValue: '3' },
      { id: 4, arrayValue: '4' },
      { id: 5, arrayValue: '5' },
      { id: 6, arrayValue: '6' },
      { id: 7, arrayValue: '7' },
      { id: 8, arrayValue: '8' },
      { id: 9, arrayValue: '9' },
    ],
  },
  {
    id: 4,
    element: 'State Residency',
    stateName: 'residency',
    array: [
      { id: 0, arrayValue: 'None' },
      { id: 1, arrayValue: 'New York' },
      { id: 2, arrayValue: 'New York City' },
    ],
  },
  {
    id: 5,
    element: 'Safe Harbor Comparison',
    stateName: 'safeHarborComparison',
    array: [
      { id: 0, arrayValue: 'No' },
      { id: 1, arrayValue: 'Yes' },
    ],
  },
];

const taxAssumptionsDefault: ITaxAssumptionsReduxModel = {
  quarter: '1',
  filingStatus: 'Single',
  standard: 'Optimize',
  dependents: '0',
  residency: 'None',
  safeHarborComparison: 'No',
};

const clientInfoDefault: IClientInfo = {
  clientName: '',
  clientId: '',
};

const safeHarborInputDefault: ISafeHarborInput = {
  federalSafeHarborPercentage: '100%',
  stateSafeHarborPercentage: '100%',
  federalPriorYearTax: '0',
  statePriorYearTax: '0',
};

const safeHarborPercentages: ISafeHarborPercentages[] = [
  { key: 0, arrayValue: '90%' },
  { key: 1, arrayValue: '100%' },
  { key: 2, arrayValue: '110%' },
  { key: 3, arrayValue: '120%' },
];

const safeHarborSelectElements: ISafeHarborElements[] = [
  { key: 0, element: 'Federal Safe Harbor Percentage', stateName: 'federalSafeHarborPercentage', array: safeHarborPercentages},
  { key: 1, element: 'State Safe Harbor Percentage', stateName: 'stateSafeHarborPercentage', array: safeHarborPercentages },
];

const safeHarborInputElements: ISafeHarborElements[] = [
  { key: 0, element: 'Federal Prior Year Tax', stateName: 'federalPriorYearTax' },
  { key: 1, element: 'State Prior Tear Tax', stateName: 'statePriorYearTax' },
];

// numbersInputDefault for each description key must match respective constants.js hardValue
const numbersInputDefault: INumbersInput = {
  wages: '0',
  interest: '0',
  ordinaryDividends: '0',
  qualifiedDividends: '0',
  retirementIncome: '0',
  shortCapitalIncome: '0',
  longCapitalIncome: '0',
  businessIncome: '0',
  otherIncome: '0',
  hsa: '0',
  seHealthInsurance: '0',
  retirementDeduction: '0',
  studentLoan: '0',
  otherAdjustments: '0',
  medical: '0',
  taxesPaidItemized: '0',
  interestPaidItemized: '0',
  charitySixty: '0',
  charityFifty: '0',
  charityThirty: '0',
  charityOther: '0',
  otherFederalItemized: '0',
  statePortfolioItemized: '0',
  stateSpecificItemized: '0',
  qbi: '0',
  otherFedDeductions: '0',
  otherFedTaxes: '0',
  otherStateIncomeDeductions: '0',
  stateAdditions: '0',
  stateSubtractions: '0',
  otherStateTaxes: '0',
  fedWagesWithholding: '0',
  fedPYOP: '0',
  fedQ1: '0',
  fedQ2: '0',
  fedQ3: '0',
  fedQ4: '0',
  fedCredits: '0',
  stateWagesWithholding: '0',
  statePYOP: '0',
  stateQ1: '0',
  stateQ2: '0',
  stateQ3: '0',
  stateQ4: '0',
  stateCredits: '0',
};

const numbersAnnualizationDefault: INumbersAnnualization = {
  wages: 'on',
  interest: 'on',
  ordinaryDividends: 'on',
  qualifiedDividends: 'on',
  retirementIncome: 'on',
  shortCapitalIncome: 'off',
  longCapitalIncome: 'off',
  businessIncome: 'on',
  otherIncome: 'on',
  hsa: 'on',
  seHealthInsurance: 'on',
  retirementDeduction: 'on',
  studentLoan: 'on',
  otherAdjustments: 'on',
  medical: 'on',
  taxesPaidItemized: 'on',
  interestPaidItemized: 'on',
  charitySixty: 'on',
  charityFifty: 'on',
  charityThirty: 'on',
  charityOther: 'on',
  otherFederalItemized: 'on',
  stateSpecificItemized: 'on',
  qbi: 'on',
  otherFedDeductions: 'on',
  otherFedTaxes: 'on',
  statePortfolioItemized: 'on',
  otherStateIncomeDeductions: 'on',
  stateAdditions: 'on',
  stateSubtractions: 'on',
  otherStateTaxes: 'on',
  fedWagesWithholding: 'on',
  fedPYOP: 'na',
  fedQ1: 'na',
  fedQ2: 'na',
  fedQ3: 'na',
  fedQ4: 'na',
  fedCredits: 'on',
  stateWagesWithholding: 'on',
  statePYOP: 'na',
  stateQ1: 'na',
  stateQ2: 'na',
  stateQ3: 'na',
  stateQ4: 'na',
  stateCredits: 'on',
};

const taxIncomeElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Wages (W-2)', hardValue: 'wages' },
  { id: 1, element: 'Interest', hardValue: 'interest' },
  { id: 2, element: 'Ordinary Dividends', hardValue: 'ordinaryDividends' },
  { id: 3, element: 'Qualified Dividends', hardValue: 'qualifiedDividends' },
  { id: 4, element: 'Retirement Income', hardValue: 'retirementIncome' },
  { id: 5, element: 'Short-Term Capital Gains/(Losses)', hardValue: 'shortCapitalIncome' },
  { id: 6, element: 'Long-Term Capital Gains/(Losses)', hardValue: 'longCapitalIncome' },
  { id: 7, element: 'Business Income (Schedule C)', hardValue: 'businessIncome' },
  { id: 8, element: 'Other Income', hardValue: 'otherIncome' },
];

const taxAdjustmentElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'HSA Contributions', hardValue: 'hsa' },
  { id: 1, element: 'S/E Health Insurance', hardValue: 'seHealthInsurance' },
  { id: 2, element: 'Retirement Deduction', hardValue: 'retirementDeduction' },
  { id: 3, element: 'Student Loan Interest', hardValue: 'studentLoan' },
  { id: 4, element: 'Other Adjustments', hardValue: 'otherAdjustments' },
];

const taxItemizedDeductionElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Medical *(SL)', hardValue: 'medical' },
  { id: 1, element: 'Taxes Paid *(SL)', hardValue: 'taxesPaidItemized' },
  { id: 2, element: 'Interest Paid', hardValue: 'interestPaidItemized' },
  { id: 3, element: 'Charity (60%) *(SL)', hardValue: 'charitySixty' },
  { id: 4, element: 'Charity (50%) *(SL)', hardValue: 'charityFifty' },
  { id: 5, element: 'Charity (30%) *(SL)', hardValue: 'charityThirty' },
  { id: 6, element: 'Charity Other', hardValue: 'charityOther' },
  { id: 7, element: 'Other Federal Itemized Deductions', hardValue: 'otherFederalItemized' },
];

const taxStateItemizedDeductionElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'State Portfolio Deductions *(SL)', hardValue: 'statePortfolioItemized' },
  { id: 1, element: 'State Specific Itemized Deductions', hardValue: 'stateSpecificItemized' },
];

const taxOtherFederal: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Qualified Business Income Deduction (S/B Negative)', hardValue: 'qbi' },
  { id: 1, element: 'Other Federal Income /(Deductions)', hardValue: 'otherFedDeductions' },
];

const taxOtherState: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Other State Income /(Deductions)', hardValue: 'otherStateIncomeDeductions' },
  { id: 1, element: 'State Additions (S/B Positive)', hardValue: 'stateAdditions' },
  { id: 2, element: 'State Subtractions (S/B Negative)', hardValue: 'stateSubtractions' },
];

const paymentsFederal: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Wages Withholding', hardValue: 'fedWagesWithholding' },
  { id: 1, element: 'Prior Year Overpayment', hardValue: 'fedPYOP' },
  { id: 2, element: 'First Quarter Estimate', hardValue: 'fedQ1' },
  { id: 3, element: 'Second Quarter Estimate', hardValue: 'fedQ2' },
  { id: 4, element: 'Third Quarter Estimate', hardValue: 'fedQ3' },
  { id: 5, element: 'Fourth Quarter Estimate', hardValue: 'fedQ4' },
  { id: 6, element: 'Credits', hardValue: 'fedCredits' },
];

const paymentsState: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Wages Withholding', hardValue: 'stateWagesWithholding' },
  { id: 1, element: 'Prior Year Overpayment', hardValue: 'statePYOP' },
  { id: 2, element: 'First Quarter Estimate', hardValue: 'stateQ1' },
  { id: 3, element: 'Second Quarter Estimate', hardValue: 'stateQ2' },
  { id: 4, element: 'Third Quarter Estimate', hardValue: 'stateQ3' },
  { id: 5, element: 'Fourth Quarter Estimate', hardValue: 'stateQ4' },
  { id: 6, element: 'Credits', hardValue: 'stateCredits' },
];

const otherTaxes: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Other Federal Taxes', hardValue: 'otherFedTaxes' },
  { id: 1, element: 'Other State Taxes', hardValue: 'otherStateTaxes' },
];

// all numbers below this will change from year to year, currently constants are for 2022

// 2024
const maxSETaxThreshold = 168600;

// 2024 - Behind By A Year
const schDLineSix: ISchDModel[] = [
  { id: 0, filingStatus: 'Single', value: 47025 },
  { id: 1, filingStatus: 'MFJ', value: 94050 },
  { id: 2, filingStatus: 'MFS', value: 47025 },
  { id: 3, filingStatus: 'HOH', value: 63000 },
];

// 2024 - Behind By A Year
const schDLineThirteen: ISchDModel[] = [
  { id: 0, filingStatus: 'Single', value: 518900 },
  { id: 1, filingStatus: 'MFJ', value: 583750 },
  { id: 2, filingStatus: 'MFS', value: 291850 },
  { id: 3, filingStatus: 'HOH', value: 551350 },
];

// ALL 2024 BELOW

const federalSingleTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11600 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1160, taxBracketFloor: 11601, taxBracketCeiling: 47150 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5426, taxBracketFloor: 47151, taxBracketCeiling: 100525 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 17169, taxBracketFloor: 100526, taxBracketCeiling: 191950 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 39111, taxBracketFloor: 191951, taxBracketCeiling: 243725 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 55679, taxBracketFloor: 243726, taxBracketCeiling: 609350 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 183647, taxBracketFloor: 609351, taxBracketCeiling: undefined },
];

const federalMFJTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 23200 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 2320, taxBracketFloor: 23201, taxBracketCeiling: 94300 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 10852, taxBracketFloor: 94301, taxBracketCeiling: 201050 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 34337, taxBracketFloor: 201051, taxBracketCeiling: 383900 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 78221, taxBracketFloor: 383901, taxBracketCeiling: 487450 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 111357, taxBracketFloor: 487451, taxBracketCeiling: 731200 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 196670, taxBracketFloor: 731201, taxBracketCeiling: undefined },
];

const federalMFSTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11600 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1160, taxBracketFloor: 11601, taxBracketCeiling: 47150 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5426, taxBracketFloor: 47151, taxBracketCeiling: 100525 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 17169, taxBracketFloor: 100526, taxBracketCeiling: 191950 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 39111, taxBracketFloor: 191951, taxBracketCeiling: 243725 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 55679, taxBracketFloor: 243726, taxBracketCeiling: 365600 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 98335, taxBracketFloor: 365601, taxBracketCeiling: undefined },
];

const federalHOHTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 16550 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1655, taxBracketFloor: 16551, taxBracketCeiling: 63100 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 7241, taxBracketFloor: 63101, taxBracketCeiling: 100500 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 15469, taxBracketFloor: 100501, taxBracketCeiling: 191950 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 37417, taxBracketFloor: 191951, taxBracketCeiling: 243700 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 53977, taxBracketFloor: 243701, taxBracketCeiling: 609350 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 181955, taxBracketFloor: 609351, taxBracketCeiling: undefined },
];

const newYorkStateSingleTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateMFJTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 17150 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 686, taxBracketFloor: 17151, taxBracketCeiling: 23600 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 976, taxBracketFloor: 23601, taxBracketCeiling: 27900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 1202, taxBracketFloor: 27901, taxBracketCeiling: 161550 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 8553, taxBracketFloor: 161551, taxBracketCeiling: 323200 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 18252, taxBracketFloor: 323201, taxBracketCeiling: 2155350 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 143754, taxBracketFloor: 2155351, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 418263, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2478263, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateMFSTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateHOHTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12800 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 512, taxBracketFloor: 12801, taxBracketCeiling: 17650 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 730, taxBracketFloor: 17651, taxBracketCeiling: 20900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 901, taxBracketFloor: 20901, taxBracketCeiling: 107650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 5672, taxBracketFloor: 107651, taxBracketCeiling: 269300 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 15371, taxBracketFloor: 269301, taxBracketCeiling: 1616450 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 107651, taxBracketFloor: 1616451, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 434163, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2494163, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkCitySingleTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
];

const newYorkCityMFJTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 21600 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 665, taxBracketFloor: 21601, taxBracketCeiling: 45000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1545, taxBracketFloor: 45001, taxBracketCeiling: 90000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 3264, taxBracketFloor: 90001, taxBracketCeiling: undefined },
];

const newYorkCityMFSTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
];

const newYorkCityHOHTaxBrackets: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 14400 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 443, taxBracketFloor: 14401, taxBracketCeiling: 30000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1030, taxBracketFloor: 30001, taxBracketCeiling: 60000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 2176, taxBracketFloor: 60001, taxBracketCeiling: undefined },
];


//  ALL 2023 BELOW
const federalStandardDeduction: IStandardDeductionModel[] = [
  { id: 0, standardDeduction: -14600, filingStatus: 'Single' },
  { id: 1, standardDeduction: -14600, filingStatus: 'MFS' },
  { id: 2, standardDeduction: -29200, filingStatus: 'MFJ' },
  { id: 3, standardDeduction: -21900, filingStatus: 'HOH' },
];

const newYorkStandardDeduction: IStandardDeductionModel[] = [
  { id: 0, standardDeduction: -8000, filingStatus: 'Single' },
  { id: 1, standardDeduction: -8000, filingStatus: 'MFS' },
  { id: 2, standardDeduction: -16050, filingStatus: 'MFJ' },
  { id: 3, standardDeduction: -11200, filingStatus: 'HOH' },
];

const initialUserDataClientInfos: IUserDataClientInfos[] = [
  {
    clientInfo: {
      clientName: '',
      clientId: '',
    },
  },
];

export {
  numbersInputDefault,
  numbersAnnualizationDefault,
  taxAssumptionsElements,
  taxAssumptionsDefault,
  clientInfoDefault,
  safeHarborInputDefault,
  safeHarborSelectElements,
  safeHarborInputElements,
  taxIncomeElements,
  taxAdjustmentElements,
  taxItemizedDeductionElements,
  taxStateItemizedDeductionElements,
  taxOtherFederal,
  taxOtherState,
  paymentsFederal,
  paymentsState,
  otherTaxes,
  maxSETaxThreshold,
  schDLineSix,
  schDLineThirteen,
  federalSingleTaxBrackets,
  federalMFJTaxBrackets,
  federalMFSTaxBrackets,
  federalHOHTaxBrackets,
  newYorkStateSingleTaxBrackets,
  newYorkStateMFJTaxBrackets,
  newYorkStateMFSTaxBrackets,
  newYorkStateHOHTaxBrackets,
  newYorkCitySingleTaxBrackets,
  newYorkCityMFJTaxBrackets,
  newYorkCityMFSTaxBrackets,
  newYorkCityHOHTaxBrackets,
  federalStandardDeduction,
  newYorkStandardDeduction,
  initialUserDataClientInfos,
};

// ALL 2023 BELOW

// const maxSETaxThreshold = 160200;

// const federalSingleTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11000 },
//   { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1100, taxBracketFloor: 11001, taxBracketCeiling: 44725 },
//   { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5147, taxBracketFloor: 44726, taxBracketCeiling: 95375 },
//   { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 16290, taxBracketFloor: 95376, taxBracketCeiling: 182100 },
//   { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 37104, taxBracketFloor: 182101, taxBracketCeiling: 231250 },
//   { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 52832, taxBracketFloor: 231251, taxBracketCeiling: 578125 },
//   { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 174238, taxBracketFloor: 578126, taxBracketCeiling: undefined },
// ];

// const federalMFJTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 22000 },
//   { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 2200, taxBracketFloor: 22001, taxBracketCeiling: 89450 },
//   { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 10294, taxBracketFloor: 89451, taxBracketCeiling: 190750 },
//   { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 32580, taxBracketFloor: 190751, taxBracketCeiling: 364200 },
//   { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 74208, taxBracketFloor: 364201, taxBracketCeiling: 462500 },
//   { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 105664, taxBracketFloor: 462501, taxBracketCeiling: 693750 },
//   { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 181602, taxBracketFloor: 693751, taxBracketCeiling: undefined },
// ];

// const federalMFSTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11000 },
//   { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1100, taxBracketFloor: 11001, taxBracketCeiling: 44725 },
//   { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5147, taxBracketFloor: 44726, taxBracketCeiling: 95375 },
//   { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 16290, taxBracketFloor: 95376, taxBracketCeiling: 182100 },
//   { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 37104, taxBracketFloor: 182101, taxBracketCeiling: 231250 },
//   { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 52832, taxBracketFloor: 231251, taxBracketCeiling: 346875 },
//   { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 93301, taxBracketFloor: 346876, taxBracketCeiling: undefined },
// ];

// const federalHOHTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 15700 },
//   { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1570, taxBracketFloor: 15701, taxBracketCeiling: 59850 },
//   { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 6868, taxBracketFloor: 59851, taxBracketCeiling: 95350 },
//   { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 14678, taxBracketFloor: 95351, taxBracketCeiling: 182100 },
//   { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 35498, taxBracketFloor: 182101, taxBracketCeiling: 231250 },
//   { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 51226, taxBracketFloor: 231251, taxBracketCeiling: 578100 },
//   { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 172624, taxBracketFloor: 578101, taxBracketCeiling: undefined },
// ];

// const newYorkStateSingleTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
//   { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
//   { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
//   { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
//   { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
//   { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
//   { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
//   { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//   { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
// ];

// const newYorkStateMFJTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 17150 },
//   { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 686, taxBracketFloor: 17151, taxBracketCeiling: 23600 },
//   { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 976, taxBracketFloor: 23601, taxBracketCeiling: 27900 },
//   { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 1202, taxBracketFloor: 27901, taxBracketCeiling: 161550 },
//   { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 8553, taxBracketFloor: 161551, taxBracketCeiling: 323200 },
//   { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 18252, taxBracketFloor: 323201, taxBracketCeiling: 2155350 },
//   { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 143754, taxBracketFloor: 2155351, taxBracketCeiling: 5000000 },
//   { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 418263, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//   { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2478263, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
// ];

// const newYorkStateMFSTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
//   { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
//   { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
//   { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
//   { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
//   { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
//   { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
//   { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//   { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
// ];

// const newYorkStateHOHTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12800 },
//   { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 512, taxBracketFloor: 12801, taxBracketCeiling: 17650 },
//   { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 730, taxBracketFloor: 17651, taxBracketCeiling: 20900 },
//   { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 901, taxBracketFloor: 20901, taxBracketCeiling: 107650 },
//   { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 5672, taxBracketFloor: 107651, taxBracketCeiling: 269300 },
//   { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 15371, taxBracketFloor: 269301, taxBracketCeiling: 1616450 },
//   { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 107651, taxBracketFloor: 1616451, taxBracketCeiling: 5000000 },
//   { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 434163, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//   { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2494163, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
// ];

// const newYorkCitySingleTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
//   { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
//   { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
//   { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
// ];

// const newYorkCityMFJTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 21600 },
//   { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 665, taxBracketFloor: 21601, taxBracketCeiling: 45000 },
//   { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1545, taxBracketFloor: 45001, taxBracketCeiling: 90000 },
//   { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 3264, taxBracketFloor: 90001, taxBracketCeiling: undefined },
// ];

// const newYorkCityMFSTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
//   { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
//   { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
//   { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
// ];

// const newYorkCityHOHTaxBrackets: ITaxBracketModel[] = [
//   { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 14400 },
//   { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 443, taxBracketFloor: 14401, taxBracketCeiling: 30000 },
//   { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1030, taxBracketFloor: 30001, taxBracketCeiling: 60000 },
//   { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 2176, taxBracketFloor: 60001, taxBracketCeiling: undefined },
// ];

// const federalStandardDeduction: IStandardDeductionModel[] = [
//   { id: 0, standardDeduction: -13850, filingStatus: 'Single' },
//   { id: 1, standardDeduction: -13850, filingStatus: 'MFS' },
//   { id: 2, standardDeduction: -27700, filingStatus: 'MFJ' },
//   { id: 3, standardDeduction: -20800, filingStatus: 'HOH' },
// ];

// const newYorkStandardDeduction: IStandardDeductionModel[] = [
//   { id: 0, standardDeduction: -8000, filingStatus: 'Single' },
//   { id: 1, standardDeduction: -8000, filingStatus: 'MFS' },
//   { id: 2, standardDeduction: -16050, filingStatus: 'MFJ' },
//   { id: 3, standardDeduction: -11200, filingStatus: 'HOH' },
// ];


// const schDLineSix: ISchDModel[] = [
//   { id: 0, filingStatus: 'Single', value: 44625 },
//   { id: 1, filingStatus: 'MFJ', value: 89250 },
//   { id: 2, filingStatus: 'MFS', value: 44625 },
//   { id: 3, filingStatus: 'HOH', value: 59750 },
// ];

// const schDLineThirteen: ISchDModel[] = [
//   { id: 0, filingStatus: 'Single', value: 492300 },
//   { id: 1, filingStatus: 'MFJ', value: 553850 },
//   { id: 2, filingStatus: 'MFS', value: 276900 },
//   { id: 3, filingStatus: 'HOH', value: 523050 },
// ];

// ALL 2023 ABOVE


// 2022 - Behind By A Year
// const schDLineSix: ISchDModel[] = [
//   { id: 0, filingStatus: 'Single', value: 41675 },
//   { id: 1, filingStatus: 'MFJ', value: 41675 },
//   { id: 2, filingStatus: 'MFS', value: 83350 },
//   { id: 3, filingStatus: 'HOH', value: 55800 },
// ];

// 2022 - Behind By A Year
// const schDLineThirteen: ISchDModel[] = [
//   { id: 0, filingStatus: 'Single', value: 459750 },
//   { id: 1, filingStatus: 'MFJ', value: 258600 },
//   { id: 2, filingStatus: 'MFS', value: 517200 },
//   { id: 3, filingStatus: 'HOH', value: 488500 },
// ];

// 2021 - Behind By A Year
// const SchDLineSix: ISchDModel[] = [
//     { id: 0, filingStatus: "SINGLE", value: 40400 },
//     { id: 1, filingStatus: "MFJ", value: 40400 },
//     { id: 2, filingStatus: "MFS", value: 80800 },
//     { id: 3, filingStatus: "HOH", value: 54100 }
// ];

// 2021 - Behind By A Year
// const SchDLineThirteen: ISchDModel[] = [
//     { id: 0, filingStatus: "SINGLE", value: 445850 },
//     { id: 1, filingStatus: "MFJ", value: 250800 },
//     { id: 2, filingStatus: "MFS", value: 501600 },
//     { id: 3, filingStatus: "HOH", value: 473750 }
// ];

// ALL 2022 BELOW

// const maxSETaxThreshold: number = 147000;

// const federalSingleTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "10%", rate: .10, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 10275 },
//     { id: 1, bracket: "12%", rate: .12, totalTaxBelowBracket: 1028, taxBracketFloor: 10276, taxBracketCeiling: 41775 },
//     { id: 2, bracket: "22%", rate: .22, totalTaxBelowBracket: 4808, taxBracketFloor: 41776, taxBracketCeiling: 89075 },
//     { id: 3, bracket: "24%", rate: .24, totalTaxBelowBracket: 15214, taxBracketFloor: 89076, taxBracketCeiling: 170050 },
//     { id: 4, bracket: "32%", rate: .32, totalTaxBelowBracket: 34648, taxBracketFloor: 170051, taxBracketCeiling: 215950 },
//     { id: 5, bracket: "35%", rate: .35, totalTaxBelowBracket: 49336, taxBracketFloor: 215951, taxBracketCeiling: 539900 },
//     { id: 6, bracket: "37%", rate: .37, totalTaxBelowBracket: 162718, taxBracketFloor: 539901, taxBracketCeiling: undefined }
// ];

// const federalMFJTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "10%", rate: .10, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 20550 },
//     { id: 1, bracket: "12%", rate: .12, totalTaxBelowBracket: 2055, taxBracketFloor: 20551, taxBracketCeiling: 83550 },
//     { id: 2, bracket: "22%", rate: .22, totalTaxBelowBracket: 9615, taxBracketFloor: 83551, taxBracketCeiling: 178150 },
//     { id: 3, bracket: "24%", rate: .24, totalTaxBelowBracket: 30427, taxBracketFloor: 178151, taxBracketCeiling: 340100 },
//     { id: 4, bracket: "32%", rate: .32, totalTaxBelowBracket: 69295, taxBracketFloor: 340101, taxBracketCeiling: 431900 },
//     { id: 5, bracket: "35%", rate: .35, totalTaxBelowBracket: 98671, taxBracketFloor: 431901, taxBracketCeiling: 647850 },
//     { id: 6, bracket: "37%", rate: .37, totalTaxBelowBracket: 174254, taxBracketFloor: 647851, taxBracketCeiling: undefined }
// ];

// const federalMFSTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "10%", rate: .10, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 10275 },
//     { id: 1, bracket: "12%", rate: .12, totalTaxBelowBracket: 1028, taxBracketFloor: 10276, taxBracketCeiling: 41775 },
//     { id: 2, bracket: "22%", rate: .22, totalTaxBelowBracket: 4808, taxBracketFloor: 41776, taxBracketCeiling: 89075 },
//     { id: 3, bracket: "24%", rate: .24, totalTaxBelowBracket: 15214, taxBracketFloor: 89076, taxBracketCeiling: 170050 },
//     { id: 4, bracket: "32%", rate: .32, totalTaxBelowBracket: 34648, taxBracketFloor: 170051, taxBracketCeiling: 215950 },
//     { id: 5, bracket: "35%", rate: .35, totalTaxBelowBracket: 49336, taxBracketFloor: 215951, taxBracketCeiling: 323925 },
//     { id: 6, bracket: "37%", rate: .37, totalTaxBelowBracket: 87127, taxBracketFloor: 323926, taxBracketCeiling: undefined }
// ];

// const federalHOHTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "10%", rate: .10, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 14650 },
//     { id: 1, bracket: "12%", rate: .12, totalTaxBelowBracket: 1465, taxBracketFloor: 14651, taxBracketCeiling: 55900 },
//     { id: 2, bracket: "22%", rate: .22, totalTaxBelowBracket: 6415, taxBracketFloor: 55901, taxBracketCeiling: 89050 },
//     { id: 3, bracket: "24%", rate: .24, totalTaxBelowBracket: 13708, taxBracketFloor: 89051, taxBracketCeiling: 170050 },
//     { id: 4, bracket: "32%", rate: .32, totalTaxBelowBracket: 33148, taxBracketFloor: 170051, taxBracketCeiling: 215950 },
//     { id: 5, bracket: "35%", rate: .35, totalTaxBelowBracket: 47836, taxBracketFloor: 215951, taxBracketCeiling: 539900 },
//     { id: 6, bracket: "37%", rate: .37, totalTaxBelowBracket: 161219, taxBracketFloor: 539901, taxBracketCeiling: undefined }
// ];

// const newYorkStateSingleTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "4%", rate: .04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
//     { id: 1, bracket: "4.5%", rate: .045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
//     { id: 2, bracket: "5.25%", rate: .0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
//     { id: 3, bracket: "5.85%", rate: .0585, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
//     { id: 4, bracket: "6.25%", rate: .0625, totalTaxBelowBracket: 4504, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
//     { id: 5, bracket: "6.85%", rate: .0685, totalTaxBelowBracket: 12926, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
//     { id: 6, bracket: "9.65%", rate: .0965, totalTaxBelowBracket: 71984, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
//     { id: 7, bracket: "10.3%", rate: .103, totalTaxBelowBracket: 450500, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//     { id: 8, bracket: "10.9%", rate: .109, totalTaxBelowBracket: 2510500, taxBracketFloor: 25000001, taxBracketCeiling: undefined }
// ];

// const newYorkStateMFJTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "4%", rate: .04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 17150 },
//     { id: 1, bracket: "4.5%", rate: .045, totalTaxBelowBracket: 686, taxBracketFloor: 17151, taxBracketCeiling: 23600 },
//     { id: 2, bracket: "5.25%", rate: .0525, totalTaxBelowBracket: 976, taxBracketFloor: 23601, taxBracketCeiling: 27900 },
//     { id: 3, bracket: "5.85%", rate: .0585, totalTaxBelowBracket: 1202, taxBracketFloor: 27901, taxBracketCeiling: 161550 },
//     { id: 4, bracket: "6.25%", rate: .0625, totalTaxBelowBracket: 9021, taxBracketFloor: 161551, taxBracketCeiling: 323200 },
//     { id: 5, bracket: "6.85%", rate: .0685, totalTaxBelowBracket: 19124, taxBracketFloor: 323201, taxBracketCeiling: 2155350 },
//     { id: 6, bracket: "9.65%", rate: .0965, totalTaxBelowBracket: 144626, taxBracketFloor: 2155351, taxBracketCeiling: 5000000 },
//     { id: 7, bracket: "10.3%", rate: .103, totalTaxBelowBracket: 419135, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//     { id: 8, bracket: "10.9%", rate: .109, totalTaxBelowBracket: 2479135, taxBracketFloor: 25000001, taxBracketCeiling: undefined }
// ];

// const newYorkStateMFSTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "4%", rate: .04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
//     { id: 1, bracket: "4.5%", rate: .045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
//     { id: 2, bracket: "5.25%", rate: .0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
//     { id: 3, bracket: "5.85%", rate: .0585, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
//     { id: 4, bracket: "6.25%", rate: .0625, totalTaxBelowBracket: 4504, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
//     { id: 5, bracket: "6.85%", rate: .0685, totalTaxBelowBracket: 12926, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
//     { id: 6, bracket: "9.65%", rate: .0965, totalTaxBelowBracket: 71984, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
//     { id: 7, bracket: "10.3%", rate: .103, totalTaxBelowBracket: 450500, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//     { id: 8, bracket: "10.9%", rate: .109, totalTaxBelowBracket: 2510500, taxBracketFloor: 25000001, taxBracketCeiling: undefined }
// ];

// const newYorkStateHOHTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "4%", rate: .04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12800 },
//     { id: 1, bracket: "4.5%", rate: .045, totalTaxBelowBracket: 512, taxBracketFloor: 12801, taxBracketCeiling: 17650 },
//     { id: 2, bracket: "5.25%", rate: .0525, totalTaxBelowBracket: 730, taxBracketFloor: 17651, taxBracketCeiling: 20900 },
//     { id: 3, bracket: "5.85%", rate: .0585, totalTaxBelowBracket: 901, taxBracketFloor: 20901, taxBracketCeiling: 107650 },
//     { id: 4, bracket: "6.25%", rate: .0625, totalTaxBelowBracket: 5976, taxBracketFloor: 107651, taxBracketCeiling: 269300 },
//     { id: 5, bracket: "6.85%", rate: .0685, totalTaxBelowBracket: 16079, taxBracketFloor: 269301, taxBracketCeiling: 1616450 },
//     { id: 6, bracket: "9.65%", rate: .0965, totalTaxBelowBracket: 108359, taxBracketFloor: 1616451, taxBracketCeiling: 5000000 },
//     { id: 7, bracket: "10.3%", rate: .103, totalTaxBelowBracket: 434871, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
//     { id: 8, bracket: "10.9%", rate: .109, totalTaxBelowBracket: 2494871, taxBracketFloor: 25000001, taxBracketCeiling: undefined }
// ];

// const newYorkCitySingleTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "3.078%", rate: .03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
//     { id: 1, bracket: "3.762%", rate: .03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
//     { id: 2, bracket: "3.819%", rate: .03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
//     { id: 3, bracket: "3.876%", rate: .03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined }
// ];

// const newYorkCityMFJTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "3.078%", rate: .03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 21600 },
//     { id: 1, bracket: "3.762%", rate: .03762, totalTaxBelowBracket: 665, taxBracketFloor: 21601, taxBracketCeiling: 45000 },
//     { id: 2, bracket: "3.819%", rate: .03819, totalTaxBelowBracket: 1545, taxBracketFloor: 45001, taxBracketCeiling: 90000 },
//     { id: 3, bracket: "3.876%", rate: .03876, totalTaxBelowBracket: 3264, taxBracketFloor: 90001, taxBracketCeiling: undefined }
// ];

// const newYorkCityMFSTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "3.078%", rate: .03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
//     { id: 1, bracket: "3.762%", rate: .03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
//     { id: 2, bracket: "3.819%", rate: .03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
//     { id: 3, bracket: "3.876%", rate: .03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined }
// ];

// const newYorkCityHOHTaxBrackets: ITaxBracketModel[] = [
//     { id: 0, bracket: "3.078%", rate: .03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 14400 },
//     { id: 1, bracket: "3.762%", rate: .03762, totalTaxBelowBracket: 443, taxBracketFloor: 14401, taxBracketCeiling: 30000 },
//     { id: 2, bracket: "3.819%", rate: .03819, totalTaxBelowBracket: 1030, taxBracketFloor: 30001, taxBracketCeiling: 60000 },
//     { id: 3, bracket: "3.876%", rate: .03876, totalTaxBelowBracket: 2176, taxBracketFloor: 60001, taxBracketCeiling: undefined }
// ];

// const federalStandardDeduction: IStandardDeductionModel[] = [
//     { id: 0, standardDeduction: -12950, filingStatus: "SINGLE" },
//     { id: 1, standardDeduction: -12950, filingStatus: "MFS" },
//     { id: 2, standardDeduction: -25900, filingStatus: "MFJ" },
//     { id: 3, standardDeduction: -19400, filingStatus: "HOH" }
// ];

// const newYorkStandardDeduction: IStandardDeductionModel[] = [
//     { id: 0, standardDeduction: -8000, filingStatus: "SINGLE" },
//     { id: 1, standardDeduction: -8000, filingStatus: "MFS" },
//     { id: 2, standardDeduction: -16050, filingStatus: "MFJ" },
//     { id: 3, standardDeduction: -11200, filingStatus: "HOH" }
// ];
