import { OnClickFunction } from './types';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';

interface ListItemComponentProps {
    text: string;
    onClick: OnClickFunction;
  }

const ListItemComponent = (props: ListItemComponentProps) => {
   
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={props.onClick}>
        <ListItemText primary={props.text} />
      </ListItemButton> 
    </ListItem>
  );
};
 
export default ListItemComponent;
