import { INumbersInput, INumbersInputAsNumbers } from '../types';

const convertStringsToNumbers = (obj: INumbersInput): INumbersInputAsNumbers => {
  const convertedObj: Partial<INumbersInputAsNumbers> = {};

  Object.keys(obj).forEach(key => {
    let value = parseFloat(obj[key as keyof INumbersInputAsNumbers]);
    if (isNaN(value)) {
      value = 0;
    }
    convertedObj[key as keyof INumbersInputAsNumbers] = value;

  });

  return convertedObj as INumbersInputAsNumbers;
};

export default convertStringsToNumbers;
