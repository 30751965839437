import { Link } from 'react-router-dom';
import Box from '@mui/material/Box'; 
import './header.scss';

type TryNowButtonProps = { backgroundStyleEven: boolean };

const TryNowButton = ({backgroundStyleEven}: TryNowButtonProps) => {

  const backgroundStyle: string = backgroundStyleEven ? '12% 0' : '2% 0 10% 0';
  const borderStyle: string = backgroundStyleEven ? '5px solid rgba(0, 0, 0, 0.5)' : 'none';
  const boxShadowTop: string = backgroundStyleEven ? '0px -4px 8px rgba(0, 0, 0, 0.2)' : 'none';
  const boxShadowBottom: string = backgroundStyleEven ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none';

  return ( 
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: backgroundStyle, sm: '7% 0', md: '3% 0' },
        background: 'linear-gradient(to top, #57a1f8, #0047ab)',
        borderBottom: borderStyle,
        boxShadow: `${boxShadowTop}, ${boxShadowBottom}`,
      }}
    >
      <Link to="/taxapp" style={{ padding: '20px 30px' }} className='try-now-button'>
        TRY NOW!
      </Link>
    </Box>
  );
}
 
export default TryNowButton;

