import { taxAssumptionsElements, taxAssumptionsDefault, safeHarborInputDefault } from '../constants/generalConstants';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { safeHarborInputAction, taxAssumptionsAction, taxAssumptionsSelector } from '../../redux/reducers/taxProjectionDataSlice';
import './taxAssumptions.scss';

interface Props {
  sideBarNavSummaryShow: boolean;
}

const TaxAssumptionsBox = (props: Props) => {

  const taxAssumptionsFromStore = useAppSelector(taxAssumptionsSelector);
  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(taxAssumptionsAction(taxAssumptionsDefault));
    dispatch(safeHarborInputAction(safeHarborInputDefault));
  };

  const handleChange = (elementName: string, e: { target: HTMLSelectElement }) => {
    const tempValue = e.target.value;
    const oldtaxAssumptionsFromStore = taxAssumptionsFromStore;
    const newtaxAssumptionsState = {
      ...oldtaxAssumptionsFromStore,
      [elementName]: tempValue,
    };
    dispatch(taxAssumptionsAction(newtaxAssumptionsState));
  };

  return (
    <div>
      <div id={props.sideBarNavSummaryShow ? 'tax-assumptions-box' : 'tax-assumptions-box-full-width'}>
        <div className="tax-assumptions-container">
          <div className="box-title">TAX ASSUMPTIONS</div>
          <div className="tax-assumptions-grid">
            {taxAssumptionsElements.map((item) =>
              <div className="tax-assumptions-grid-item tax-assumptions-custom-margin" key={item.id}>
                <div className="tax-assumptions-child-grid-item">
                  <div className="tax-assumptions-label" key={item.id}>{item.element}:&nbsp;</div>
                </div>
                <div className="tax-assumptions-child-grid-item">
                  <select
                    name={item.element}
                    className="tax-assumptions-select"
                    id="tax-assumption-item"
                    onChange={(e) => handleChange(item.stateName, e)}
                    value={taxAssumptionsFromStore[item.stateName]}>
                    {item.array.map((value) =>
                      <option key={value.id} value={value.arrayValue}>{value.arrayValue}</option>
                    )};
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex-container-reset-button">
          <button id="tax-assumptions-reset-button" className="reset-button" onClick={handleReset}>
              RESET
          </button>
        </div>
      </div>
    </div>
  );
};


export default TaxAssumptionsBox;
