import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Auth0Provider, AuthorizationParams } from '@auth0/auth0-react';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') disableReactDevTools();

const domain = process.env.REACT_APP_DOMAIN || '';
const clientId = process.env.REACT_APP_CLIENT_ID || '';
const audience = process.env.REACT_APP_AUDIENCE;
const scope = process.env.REACT_APP_SCOPE || '';
const scopeConverted = scope.replace(/\//g, ' ');
const authorizationParams: AuthorizationParams = {
  audience: audience,
  scope: scopeConverted,
  redirect_uri: window.location.origin,
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        useRefreshTokens={true}
        useRefreshTokensFallback={false}
        authorizationParams={authorizationParams}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
