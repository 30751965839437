import './pitch.scss';

const Pitch = () => {
  return ( 
    <div className='pitch-container'>
      <div className='pitch-container-content'>
        <img src='/landingPageImage.jpg' alt='Tax Image' />
        <p>
        Looking for an affordable, straightforward way to manage your taxes? If complex tax software isn't for you, and the basic online tools don't meet your needs, 
        TaxProjection.com is here to help. 
        This platform is designed to simplify tax projections for most people, making it easier than ever to plan ahead.
        </p>
      </div>
    </div>
  );
}
 
export default Pitch;
