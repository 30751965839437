import StateColumnView from './StateColumnView';
import './numbersOutput.scss';

interface Props {
    federalStandard: number | string | undefined;
    stateStandard: number | string | undefined;
    NA: string;
    residencyFromStore: string;
    hardValue?: string;
}

const StandardOutput = (props: Props) => {

  return (
    <tbody style={{ borderTop: 'none' }}>
      <tr className="table-row-item-regular">
        <th scope="row" className="table-description-item">Standard Deduction</th>
        <td>{props.federalStandard}</td>
        {<StateColumnView
          residency={props.residencyFromStore}
          nAValue={props.NA}
          outputValue={props.stateStandard}
          hardValue={props.hardValue}
        />}
      </tr>
    </tbody>
  );
};

export default StandardOutput;
