import ItemizedOutput from './ItemizedOutput';
import StandardOutput from './StandardOutput';
import { IItemizedDeductionsCalcFederal, IItemizedDeductionsCalcNewYork } from '../../types';
import './numbersOutput.scss';

interface Props {
  federalOptimizedOption: string;
  stateOptimizedOption: string;
  federalStandard: number | string | undefined;
  stateStandard: number | string | undefined;
  NA: string;
  residencyFromStore: string;
  itemizedDeductionsCalcFederalValues: IItemizedDeductionsCalcFederal;
  itemizedDeductionsCalcStateValues: IItemizedDeductionsCalcNewYork;
  totalFederalItemized: number | string;
  totalStateItemized: number | string;
  hideUnusedRows: boolean;
}

const OptimizedStandardItemizedOutput = (props: Props) => {

  if (props.federalOptimizedOption === 'Standard' && props.stateOptimizedOption === 'Standard') {
    return ( 
      <StandardOutput
        federalStandard={props.federalStandard}
        stateStandard={props.stateStandard}
        NA={props.NA}
        residencyFromStore={props.residencyFromStore}
      />
    );
  }
  else if (props.federalOptimizedOption === 'Itemized' && props.stateOptimizedOption === 'Itemized') {
    return (
      <ItemizedOutput
        itemizedDeductionsCalcFederalValues={props.itemizedDeductionsCalcFederalValues}
        itemizedDeductionsCalcStateValues={props.itemizedDeductionsCalcStateValues}
        totalFederalItemized={props.totalFederalItemized}
        totalStateItemized={props.totalStateItemized}
        NA={props.NA}
        residencyFromStore={props.residencyFromStore}
        hideUnusedRows={props.hideUnusedRows}
      />
    );
  }
  else {
    return (
      <>
        <StandardOutput
          federalStandard={props.federalStandard}
          stateStandard={props.stateStandard}
          NA={props.NA}
          residencyFromStore={props.residencyFromStore}
        />
        <ItemizedOutput
          itemizedDeductionsCalcFederalValues={props.itemizedDeductionsCalcFederalValues}
          itemizedDeductionsCalcStateValues={props.itemizedDeductionsCalcStateValues}
          totalFederalItemized={props.totalFederalItemized}
          totalStateItemized={props.totalStateItemized}
          NA={props.NA}
          residencyFromStore={props.residencyFromStore}
          hideUnusedRows={props.hideUnusedRows}
        />
      </>
    );
  }
};
 
export default OptimizedStandardItemizedOutput;
