import { useState, useRef } from 'react';
import Header from './components/Header';
import SideBarToggle from './components/SideNavBarSummary/SideBarToggle';
import SideBarNavSummary from './components/SideNavBarSummary/SideBarNavSummary';
import ClientTitleBox from './components/ClientTitleBox';
import TaxAssumptionsBox from './components/TaxAssumptionsBox';
import NumbersInput from './components/NumbersInput/NumbersInput';
import NumbersOutput from './components/NumbersOutput/NumbersOutput';
import TaxNotes from './components/TaxNotes';
import Footer from './components/Footer';
import SafeHarborInput from './components/SafeHarborInput';
import WelcomeAlert from './components/WelcomeAlert';
import './printStyles.scss';

const TaxProjectionApp = () => {

  const [sideBarNavSummaryShow, setSideBarNavSummaryShow ] = useState(true);

  // Refs passed directly to respective Children Components for referencing and manipulated through SideBarNavSummary component to be changed.
  const clientTitleBoxRef = useRef(null);
  const taxAssumptionsBoxRef = useRef(null);
  const safeHarborBoxRef = useRef(null);
  const numbersOutputRef = useRef(null);
  const taxNotesRef = useRef(null);

  // Refs passed directly to Child Component NumbersInput to be passed down to specific 
  // Grandchildren Components NumbersInputComponent for referencing. Not every GrandChild Component needs a ref.
  // Manipulated through SideBarNavSummary component to be changed.
  const inputIncomeRef = useRef(null);
  const inputAdjustmentsRef = useRef(null);
  const inputItemizedRef = useRef(null);
  const inputOtherRef = useRef(null);
  const inputFedPmtsRef = useRef(null);
  const inputStatePmtsRef = useRef(null);

  const inputRefs = {
    inputIncomeRef,
    inputAdjustmentsRef,
    inputItemizedRef,
    inputOtherRef,
    inputFedPmtsRef,
    inputStatePmtsRef,
  };

  const toggleSideBarNavSummary: () => void = () => {
    setSideBarNavSummaryShow((sideBarNavSummaryShow) => !sideBarNavSummaryShow);
  };

  return (
    <div className="tax-projection-app">
      <WelcomeAlert />
      <div id="printableArea">
        <Header showTitle={sideBarNavSummaryShow} />
        {
          sideBarNavSummaryShow ? 
            <SideBarNavSummary
              clientTitleBoxRef={clientTitleBoxRef}
              taxAssumptionsBoxRef={taxAssumptionsBoxRef}
              safeHarborBoxRef={safeHarborBoxRef}
              numbersOutputRef={numbersOutputRef}
              taxNotesRef={taxNotesRef}
              inputRefs={inputRefs}
              toggleSideBarNavSummary={toggleSideBarNavSummary}
            /> :
            <SideBarToggle
              toggleSideBarNavSummary={toggleSideBarNavSummary}
            />
        }
        <div className="ref-navigation" ref={clientTitleBoxRef}>
          <ClientTitleBox sideBarNavSummaryShow={sideBarNavSummaryShow} />
        </div>
        <div className="ref-navigation" ref={taxAssumptionsBoxRef}>
          <TaxAssumptionsBox sideBarNavSummaryShow={sideBarNavSummaryShow} />
        </div>
        <div className="ref-navigation" ref={safeHarborBoxRef}>
          <SafeHarborInput sideBarNavSummaryShow={sideBarNavSummaryShow} />
        </div>
        <NumbersInput refs={inputRefs} sideBarNavSummaryShow={sideBarNavSummaryShow} />
        <div className="ref-navigation" ref={numbersOutputRef}>
          <NumbersOutput sideBarNavSummaryShow={sideBarNavSummaryShow} />
        </div>
        <div className="ref-navigation" ref={taxNotesRef}>
          <TaxNotes sideBarNavSummaryShow={sideBarNavSummaryShow} />
        </div>
        <Footer sideBarNavSummaryShow={sideBarNavSummaryShow} />
      </div>
    </div>
  );
};

export default TaxProjectionApp;
