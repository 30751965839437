
// Medical or Portfolio or Other Expenses Limitation
const aGISchALimitationCalc = (expenses: number, agi: number, thresholdPercentage: number): number => {
  // Sch A Form 2022 Line 3
  const agiThreshold = agi * thresholdPercentage;
  // Sch A Form 2022 Line 4
  const expensesLimited = agiThreshold > expenses ? 0 : expenses - agiThreshold;
  return -expensesLimited;
};

export default aGISchALimitationCalc;
