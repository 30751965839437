import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function WelcomeAlert() {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Check if the device width is less than 500px
    const handleResize = () => {
      setShowAlert(window.innerWidth < 900);
    };

    // Initial check and setup event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClose = () => {
    setShowAlert(false);  // Hide the alert when the close button is clicked
  };

  return (
    <div>
      {showAlert && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert
            severity="info"
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
           We have noticed that you are using this application on a mobile device or a device with a smaller screen. This application is designed for larger 
           screens due to the extensive amount of data input required. For an optimal experience, we recommend using a device with a larger screen size.
          </Alert>
        </Stack>
      )}
    </div>
  );
}

export default WelcomeAlert;
