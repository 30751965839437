import { forwardRef, ForwardedRef } from 'react';
import {
  ITaxIncomeDeductionsPaymentsOutput,
  INumbersInput,
  INumbersAnnualization,
  numberCheckFunctionSigns,
  INumbersCheckFunction,
  INumbersInputChangeHandler
} from '../../types';

interface NumbersInputComponentProps {
  elementData: ITaxIncomeDeductionsPaymentsOutput[];
  elementData2?: ITaxIncomeDeductionsPaymentsOutput[] | undefined;
  title: string;
  numbersInputDataValues: INumbersInput;
  handleInputChange: INumbersInputChangeHandler;
  numberCheckFunction: INumbersCheckFunction;
  sign: numberCheckFunctionSigns;
  numbersAnnualizationDataValues?: INumbersAnnualization | undefined;
  handleAnnualizationButton?: (
    value: string,
    name: string,
    numbersAnnualizationObject: INumbersAnnualization
  ) => void;
}

const NumbersInputComponent = forwardRef((
  {
    elementData,
    elementData2,
    title,
    numbersInputDataValues,
    handleInputChange,
    numberCheckFunction,
    sign,
    numbersAnnualizationDataValues,
    handleAnnualizationButton
  }: NumbersInputComponentProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const YEARLY = 'YEAR';
  const QUARTERLY = 'QTR';

  return (
  // add another component that combines both sections below to reduce duplicate code? yes, this should be refactored at some point

    <div className="numbers-input-component" ref={ref}>
      <div className="numbers-input-title">{title}</div>
      <ul className="list-group-container">
        {elementData.map((item) => (
          <li
            className='list-group-item-color numbers-list-element numbers-input-grid-container'
            key={item.id}
          >
            <div className="numbers-input-grid-item">{item.element}</div>
            <div className="numbers-input-grid-item-flex">
              <input
                type="text"
                // need to fix color scheme
                id={numbersInputDataValues[item.hardValue as keyof INumbersInput] < '0' && numbersInputDataValues[item.hardValue as keyof INumbersInput] !== ''
                  ? 'numbers-input-element-red'
                  : 'numbers-input-element-black'
                }
                className="numbers-input-element"
                name={item.hardValue}
                value={
                  numbersInputDataValues[item.hardValue as keyof INumbersInput]
                }
                onChange={(e) =>
                  handleInputChange(e, numberCheckFunction, sign)
                }
              />
            </div>
            {/* whitespace */}
            <div className="numbers-input-grid-item"></div>
            {/* whitespace */}
            <div className="numbers-input-grid-item">
              {handleAnnualizationButton &&
              numbersAnnualizationDataValues &&
              numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization] !== 'na' ? (
                  <button
                    className={`annualize-input-button ${
                      numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization] === 'off'
                        ? ''
                        : 'quarterly'
                    }`}
                    onClick={() =>
                      handleAnnualizationButton(
                        numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization],
                        item.hardValue,
                        numbersAnnualizationDataValues
                      )
                    }
                  >
                    {numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization] === 'off'
                      ? YEARLY
                      : QUARTERLY}
                  </button>
                ) : null}
            </div>
          </li>
        ))}
        {elementData2 !== undefined
          ? elementData2.map((item) => (
            <li
              className='list-group-item list-group-item-color numbers-list-element numbers-input-grid-container'
              key={item.id}
            >
              <div className="numbers-input-grid-item">{item.element}</div>
              <div className="numbers-input-grid-item-flex">
                <input
                  type="text"
                  // need to fix color scheme
                  id={
                    numbersInputDataValues[item.hardValue as keyof INumbersInput] < '0' && numbersInputDataValues[item.hardValue as keyof INumbersInput] !== ''
                      ? 'numbers-input-element-red'
                      : 'numbers-input-element-black'
                  }
                  className="numbers-input-element"
                  name={item.hardValue}
                  value={
                    numbersInputDataValues[item.hardValue as keyof INumbersInput]
                  }
                  onChange={(e) =>
                    handleInputChange(e, numberCheckFunction, sign)
                  }
                />
              </div>
              {/* whitespace */}
              <div className="numbers-input-grid-item"></div>
              {/* whitespace */}
              <div className="numbers-input-grid-item">
                {handleAnnualizationButton &&
                  numbersAnnualizationDataValues &&
                  numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization] !== 'na' ? (
                    <button
                      className={`annualize-input-button ${numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization] === 'off'
                        ? ''
                        : 'quarterly'
                      }`}
                      onClick={() =>
                        handleAnnualizationButton(
                          numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization],
                          item.hardValue,
                          numbersAnnualizationDataValues
                        )
                      }
                    >
                      {numbersAnnualizationDataValues[item.hardValue as keyof INumbersAnnualization] === 'off'
                        ? YEARLY
                        : QUARTERLY}
                    </button>
                  ) : null}
              </div>
            </li>
          ))
          : null}
      </ul>
    </div>
  );
});

export default NumbersInputComponent;

// Assign a display name to your component
NumbersInputComponent.displayName = 'NumbersInputComponent';
