const annualizationFactorCalcFunction = (quarter: string): number => {

  if (quarter === '1') {
    return 4;
  }
  else if (quarter === '2') {
    return 2.4;
  }
  else if (quarter === '3') {
    return 1.5;
  }
  else {
    return 1;
  }
};

export default annualizationFactorCalcFunction;
