import {
  ITaxIncomeDeductionsPaymentsOutput,
  INumbersInputAsNumbers
} from '../types';

// dataToSummate single nested object with a number value
// constantElement is an array with single nested objects, each hardValue value matches the key to the dataToSummate object
// this function will summate numbers in numbersInputInitialState that match the specific constant Element, it is limited to specific constants, see constants for more info

const summationFunction = (dataToSummate: INumbersInputAsNumbers,
  constantElement: ITaxIncomeDeductionsPaymentsOutput[]): number => {
  const resultsArray: number[] = [];

  for (let i = 0; i < constantElement.length; i++) {
    if (dataToSummate[constantElement[i]['hardValue'] as keyof INumbersInputAsNumbers] !== 0) {
      resultsArray.push(dataToSummate[constantElement[i]['hardValue'] as keyof INumbersInputAsNumbers]);
    }
  }

  if (resultsArray.length < 1) {
    return 0;
  }
  else {
    return resultsArray.reduce((prevValue, currentValue) => prevValue + currentValue);
  }
};

export default summationFunction;
