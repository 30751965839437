import { maxSETaxThreshold } from '../constants/generalConstants';

const seTaxFunction = (businessIncome: number, wages: number): number => {
  const businessIncomeParsed: number = businessIncome;
  const wagesParsed: number = wages;
  const netBusinessIncome = businessIncomeParsed * .9235;
  const wagesLessMaxSETaxThreshold = maxSETaxThreshold - wagesParsed;
  let seTax = 0;
  let addlSETax = 0;

  if (netBusinessIncome < 400) {
    return seTax;
  }

  else if (wagesLessMaxSETaxThreshold <= 0) {
    seTax = netBusinessIncome * .029;
  }
  else if (wagesLessMaxSETaxThreshold > 0) {
    if (netBusinessIncome <= wagesLessMaxSETaxThreshold) {
      seTax = netBusinessIncome * .153;
    }
    else {
      seTax = wagesLessMaxSETaxThreshold * .124;
      addlSETax = netBusinessIncome * .029;
      seTax += addlSETax;
    }
  }
  return Math.round(seTax);
};

export default seTaxFunction;
