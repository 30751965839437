import './numbersOutput.scss';

interface Props {
    residency: string;
    outputValue: number | string | undefined;
    hardValue?: string;
    nAValue?: string;
}


const StateColumnView = (props: Props) => {

  if (props.residency === 'None') {
    return (
      null
    );
  }
  else if (props.hardValue === 'none') {
    return (
      <td className="table-na-section">{props.nAValue}</td>
    );
  }
  else {
    return (
      <td>{props.outputValue}</td>
    );
  }
};

export default StateColumnView;
