import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

interface FeatureCardItemProps {
    Icon: React.ElementType;
    title: string;
    description: string;
}

const FeatureCardItem = ({ Icon, title, description }: FeatureCardItemProps) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card 
      elevation={0}
      sx={{
        px: {
          xs: 3,
        },
        cursor: 'pointer',
        '&:hover .icon': {
          transform: 'scale(1.2)',
        }
      }}
    >
      <CardContent>
        <Icon
          className="icon" sx={{
            fontSize: 60,
            color: 'deepskyblue',
            mb: 1,
            transition: 'transform 0.3s ease-in-out',
          }}
        />
        <Typography 
          variant="h5" 
          component="h3"
          sx={{ fontWeight: 'bold', mb: 1 }}
        >
          {title}
        </Typography>
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export default FeatureCardItem;
