import { 
  IItemizedDeductionsCalcFederalAsNumbers, 
  IItemizedDeductionsCalcNewYorkAsNumbers,
  IItemizedDeductionsElements
} from '../types';

const itemizedDeductionsElements: IItemizedDeductionsElements[] = [
  { id: 0,
    element: 'Medical *(SL)',
    hardValue: 'medical',
    hardNewYorkValue: 'newYorkMedical'
  },
  { id: 1,
    element: 'Taxes Paid *(SL)',
    hardValue: 'taxesPaidItemized',
    hardNewYorkValue: 'newYorkTaxesPaidItemized'
  },
  { id: 2,
    element: 'Interest Paid',
    hardValue: 'interestPaidItemized',
    hardNewYorkValue: 'newYorkInterestPaidItemized'
  },
  { id: 3,
    element: 'Charity *(SL)',
    hardValue: 'charity',
    hardNewYorkValue: 'newYorkCharity'
  },
  { id: 4,
    element: 'Other Federal Itemized Deductions',
    hardValue: 'otherFederalItemized',
    hardNewYorkValue: 'none'
  }
];

const itemizedDeductionsNewYorkElements: IItemizedDeductionsElements[] = [
  { id: 0,
    element: 'State Portfolio Deductions *(SL)',
    hardValue: 'none',
    hardNewYorkValue: 'newYorkPortfolioDeductions'
  },
  { id: 1,
    element: 'State Specific Itemized Deductions',
    hardValue: 'none',
    hardNewYorkValue: 'newYorkStateSpecificItemized'
  },
  { id: 2,
    element: 'State Itemized Limitation *(SL)',
    hardValue: 'none',
    hardNewYorkValue: 'newYorkLimitationTotal'
  }
];

const itemizedDeductionsCalcFederalDefault: IItemizedDeductionsCalcFederalAsNumbers = {
  medical: 0,
  taxesPaidItemized: 0,
  interestPaidItemized: 0,
  charity: 0,
  otherFederalItemized: 0,
};

const itemizedDeductionsCalcNewYorkDefault: IItemizedDeductionsCalcNewYorkAsNumbers = {
  newYorkMedical: 0,
  newYorkTaxesPaidItemized: 0,
  newYorkInterestPaidItemized: 0,
  newYorkCharity: 0,
  newYorkPortfolioDeductions: 0,
  newYorkStateSpecificItemized: 0,
  newYorkLimitationTotal: 0
};


// Year to year should double check newYorkItemizedCalcFunction function under functions to see if worksheet calculations or form calculations have changed
// 2023 Sch A Limits
const schAMedicalLimitationPercentage = .075;
const schATaxesPaidMFSLimit = 5000;
const schATaxesPaidOtherLimit = 10000;
const newYorkSchAMedicalLimitationPercentage = .1;
const newYorkSchAPortfolioLimitationPercentage = .02;
const newYorkSchALineFortyThresholdMFJ = 375850;
const newYorkSchALineFortyThresholdHOH = 344500;
const newYorkSchALineFortyThresholdSingle = 313200;
const newYorkSchALineFortyThresholdMFS = 187900;
const newYorkSchALineFortySixWorksheetThreeFloor = 100000;
const newYorkSchALineFortySixWorksheetThreeCeiling = 475001;
const newYorkSchALineFortySixWorksheetFourFloor = 475000;
const newYorkSchALineFortySixWorksheetFourCeiling = 525001;
const newYorkSchALineFortySixBETWEENWorksheetFourFiveFloor = 525000;
const newYorkSchALineFortySixBETWEENWorksheetFourFiveCeiling = 1000001;
const newYorkSchALineFortySixWorksheetFiveFloor = 1000000;
const newYorkSchALineFortySixWorksheetFiveCeiling = 10000001;
const newYorkSchALineFortySixWorksheetSixFloor = 10000000;

export {
  itemizedDeductionsElements,
  itemizedDeductionsNewYorkElements,
  itemizedDeductionsCalcFederalDefault,
  itemizedDeductionsCalcNewYorkDefault,
  schAMedicalLimitationPercentage,
  schATaxesPaidMFSLimit,
  schATaxesPaidOtherLimit,
  newYorkSchAMedicalLimitationPercentage,
  newYorkSchAPortfolioLimitationPercentage,
  newYorkSchALineFortyThresholdMFJ,
  newYorkSchALineFortyThresholdHOH,
  newYorkSchALineFortyThresholdSingle,
  newYorkSchALineFortyThresholdMFS,
  newYorkSchALineFortySixWorksheetThreeFloor,
  newYorkSchALineFortySixWorksheetThreeCeiling,
  newYorkSchALineFortySixWorksheetFourFloor,
  newYorkSchALineFortySixWorksheetFourCeiling,
  newYorkSchALineFortySixBETWEENWorksheetFourFiveFloor,
  newYorkSchALineFortySixBETWEENWorksheetFourFiveCeiling,
  newYorkSchALineFortySixWorksheetFiveFloor,
  newYorkSchALineFortySixWorksheetFiveCeiling,
  newYorkSchALineFortySixWorksheetSixFloor
};

