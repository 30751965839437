import { federalStandardDeduction, newYorkStandardDeduction } from '../constants/generalConstants';
import { IStandardDeductionModel } from '../types';

const standardFunction = (jurisdiction: string, filingStatus: string): number => {
  let dependentData: IStandardDeductionModel[];

  if (jurisdiction === 'federal') {
    dependentData = federalStandardDeduction;
  }
  else if (jurisdiction === 'newYork') {
    dependentData = newYorkStandardDeduction;
  }
  else {
    // "no jurisdiction selected in standardFunction"
    console.log('no jurisdiction selected in standardFunction');
    return 0;
  }

  for (let i = 0; i < dependentData.length; i++) {
    if (dependentData[i]['filingStatus'] === filingStatus) {
      return dependentData[i].standardDeduction;
    }
  }
  // "error in standardFunction"
  console.log('error in standardFunction');
  return 0;
};

export default standardFunction;
