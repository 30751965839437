import './landingPage.scss';
import { Box, Typography } from '@mui/material/';
import UserCards from './UserCards';
import Pitch from './Pitch';
import TryNowButton from './TryNowButton';
import FeatureCards from './FeatureCards';


const Content = () => {

  return (
    <div className='content-container'>
      <div>
        <div className='title-ls-container'>
          <div className='title-ls'>
            <h1>Estimate Your Quarterly or Year-End Taxes with <strong>TaxProjection.com</strong> Today</h1>
          </div>
        </div>
        <Box sx={{
          display: { xs: 'block', sm: 'block', md: 'none' }
        }}>
          <Typography variant="body1">
            <TryNowButton backgroundStyleEven={true} />
          </Typography>
        </Box>
        <UserCards />
        <Pitch />
        <TryNowButton backgroundStyleEven={false} />
        <FeatureCards />
      </div>
    </div>
  );
};

export default Content;
