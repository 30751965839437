const cleanUpNumberStrings = (value: string): string => {
  let cleaned = value.replace(/[^0-9]/g, '');
  cleaned = cleaned === '' ? '0' : cleaned;
  cleaned = parseInt(cleaned, 10).toString();
  return cleaned;
};

export default cleanUpNumberStrings;



// import { ISafeHarborInput } from "../types";

// const cleanUpNumberStrings = (data: ISafeHarborInput): ISafeHarborInput => {
//     let newObj: { [key: string]: any } = {};
//     let keys = Object.keys(data);
//     for (let i = 0; i < keys.length; i++) {
//         let cleaned = data[keys[i] as keyof ISafeHarborInput].replace(/[^0-9]/g, '');
//         cleaned = cleaned === '' ? '0' : cleaned;
//         cleaned = parseInt(cleaned, 10).toString();
//         newObj[keys[i]] = cleaned;
//     }
//     return newObj as ISafeHarborInput;
// }

// export default cleanUpNumberStrings;
