import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@mui/material/';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import './header.scss';
import { Link } from 'react-router-dom';

interface Props {
  window?: () => Window;
}

type NavItem = {
  id: number;
  description: string;
  link: string;
};

const Header = (props: Props) => {
  const drawerWidth = 240;
  const theme = useTheme();
  const navItems: NavItem[] = [
    { id: 0, description: 'Documentation', link: 'https://github.com/russelltheprogrammer/tax-projection-individual-docs#readme' },
    { id: 1, description: 'Contact', link: '#footerContactWelcome' },
  ];
  const title = 'TAXPROJECTION.COM';

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          my: 2
        }}>
        {title}
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ textAlign: 'center' }}>
          <div className="header-list-anchor-container">
            <Link to={`/taxapp`} className="header-list-anchor">
              Try Now
            </Link>
          </div>
        </ListItem>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding sx={{ textAlign: 'center' }}>
            <div className="header-list-anchor-container" key={item.id}>
              <a
                className="header-list-anchor"
                target={item.description === 'Documentation' ? '_blank' : undefined}
                rel="noreferrer"
                href={item.link}
              >
                {item.description}
              </a>
            </div>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box>
        <AppBar
          component="nav"
          position="relative"
          sx={{
            background: 'linear-gradient(to left, #57a1f8, #0047ab)',
            py: '2%',
            textAlign: { xs: 'center', sm: 'center', md: 'left' }
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'block', sm: 'block' },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  typography: {
                    xs: 'h5',
                    sm: 'h4',
                    md: 'h4'
                  },
                  fontSize: {
                    xs: '23px',
                    sm: '28px',
                    md: '28px',
                  }
                }}
              >
                {title}
              </Typography>
            </Typography>
            <Box
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block' },
              }}
            >
              <Link to={`/taxapp`} className='try-now-button'>
                TRY NOW
              </Link>
              {navItems.map((item) => (
                <a
                  className="header-anchor"
                  target={item.description === 'Documentation' ? '_blank' : undefined}
                  rel="noreferrer"
                  key={item.id}
                  href={item.link}
                >
                  {item.description.toUpperCase()}
                </a>
              ))}
            </Box>
            <Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </div>
  );
};

export default Header;
