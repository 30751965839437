import { ITaxBracketModel } from '../types';

const taxBracketTaxFunction = (taxBracket: ITaxBracketModel[], taxableIncome: number): number => {
  let tax = 0;

  for (let i = 0; i < taxBracket.length; i++) {
    const taxBracketCeiling: number | undefined = taxBracket[i]['taxBracketCeiling'];
    const taxBracketFloor: number | undefined = taxBracket[i]['taxBracketFloor'];
    const taxBracketRate: number = taxBracket[i]['rate'];
    const totalTaxBelowBracket: number = taxBracket[i]['totalTaxBelowBracket'];

    if (taxBracketCeiling && taxableIncome <= taxBracketCeiling && taxBracketFloor === undefined) {
      tax += taxableIncome * taxBracketRate;
    }
    else if (taxBracketCeiling && taxBracketFloor && taxableIncome <= taxBracketCeiling && taxableIncome >= taxBracketFloor) {
      tax += ((taxableIncome - taxBracketFloor) * taxBracketRate) + totalTaxBelowBracket;
    }
    else if (taxBracketFloor && taxableIncome >= taxBracketFloor && taxBracketCeiling === undefined) {
      tax += ((taxableIncome - taxBracketFloor) * taxBracketRate) + totalTaxBelowBracket;
    }
  }
  return tax;
};

export default taxBracketTaxFunction;
