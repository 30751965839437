const childTaxCreditFunction = (agi: number, dependents: string, filingStatus: string): number => {
  const dependentsVariable: number = parseInt(dependents);

  if (dependentsVariable < 1) {
    return 0;
  }

  const maximumTaxCredit: number = dependentsVariable * 500; // Line 7
  const agiThreshold: number = childTaxCreditAgiFilingStatusThresholdCalc(filingStatus); // Line 9
  const agiOverThreshold: number = childTaxCreditAgiOverThresholdCalc(agiThreshold, agi); // Line 10
  const agiOverThresholdTimesFivePercent: number = agiOverThreshold * .05; // Line 11
  const minimumTaxCredit: number = agiOverThresholdTimesFivePercent - maximumTaxCredit > 0 ? agiOverThresholdTimesFivePercent - maximumTaxCredit : 0; // Line 12
  const taxCreditAllowed: number = maximumTaxCredit > minimumTaxCredit ? minimumTaxCredit : maximumTaxCredit;

  return taxCreditAllowed;

};

export default childTaxCreditFunction;


const childTaxCreditAgiFilingStatusThresholdCalc = (filingStatus: string): number => {
  if (filingStatus === 'MFJ') {
    return 400000;
  }
  else {
    return 200000;
  }
};

const childTaxCreditAgiOverThresholdCalc = (agiThreshold: number, agi: number): number => {

  const difference: number = agiThreshold - agi;
  const differenceRounded: number = Math.round(difference / 1000) * 1000;

  if (difference < 1) {
    return 0;
  }
  else if (difference >= 1 && difference < 1000) {
    return 1000;
  }
  else {
    return differenceRounded;
  }
};
