import { createTheme } from '@mui/material/styles';

export const titleTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [{
        props: { variant: 'h1' },
        style: {
          fontSize: '24px',
          fontWeight: '600',
          overflowWrap: 'break-word',
          lineHeight: '35px',
          '@media (max-width:1200px)': {
            fontSize: '17px',
          },
        },
      }],
      styleOverrides: {
        h1: {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          margin: '20px 0',
        },
      },
    },
  },
});

export const listThemeOne = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '@media (max-width:1200px)': {
            fontSize: '15px',
          },
        }
      }
    },
    MuiListItem: { 
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            opacity: '0.7',
            borderRadius: '4px'
          },
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          transition: 'width 2s linear 1s',
        },
      },
    },
  },
});

export const listThemeTwo = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        }
      }
    },
    MuiListItem: { 
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            opacity: '0.7',
            borderRadius: '4px'
          },
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          transition: 'width 2s linear 1s',
          marginLeft: '7px',
          color: '#cfe2ff',
        },
      },
    },
  },
});

export const changeButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: 'darkBlue',
          '&:hover': {
            background: 'green',
          },
          color: 'white',
          marginTop: '10%',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '18px',
          fontWeight: '600',
          padding: '3px',
          borderRadius: '5px',
          border: '3px solid lightBlue',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          transition: 'background-color 0.3s, transform 0.2s',
        },
      },
    },
  },
});
